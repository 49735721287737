import React from 'react';
import { 
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from 'react-query'

import Login from './0.1/scenes/login'
import Dashboard from './0.1/scenes/dashboard'
import Order from './0.1/scenes/order'


const queryClient = new QueryClient()


function App() {

  return (
    <div className="cra">
      <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path={`${process.env.REACT_APP_VERSION}/order/:id`} element={<Order />} />
          <Route path={`${process.env.REACT_APP_VERSION}/dashboard`} element={<Dashboard />} />
          {/*<Route path={`${process.env.REACT_APP_VERSION}/login`} element={<Login />} />*/}
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;