import React from 'react'
import { Link } from 'react-router-dom'

import { ChevronLeftIcon } from '@heroicons/react/outline';

import type { Order } from '../../../utils/types'

import './header.css'

type HeadingProps = {
    order: Order,
}

const Header: React.FC<HeadingProps> = ({ order, ...props }) => {

    return (

            <header className="order__header">
                <Link to={`/${process.env.REACT_APP_VERSION}/dashboard`} className="order__header-back"><ChevronLeftIcon className="w-5 h-5 mr-1" />Back</Link>
                <a href={`mailto:${order.E_MAIL}`} className="order__email-client">Email client</a>

                <h1 className="order__header-title">
                    <strong className="text-2xl block font-semibold">{order && [order.TITLE, order.CLIENT].join(' ')}</strong>
                    <dl className="text-xs text-center justify-center block font-semibold flex mt-1">
                        <dt className="mr-1">Order:</dt>
                        <dd>{order ? order.ORDER_REF : 'Loading order...'}</dd>
                        <dt className="mr-1 ml-3">Account:</dt>
                        <dd>{order?.ACCOUNT_NO}</dd>
                    </dl>
                    <address className="text-xs not-italic block text-center font-normal mt-1">
                        {[order?.LINE1, order?.LINE2, order?.LINE3].join(' ')}<br />
                        {[order?.TOWNCITY, order?.COUNTY, order?.POSTCODE].join(' ')}
                    </address>
                    <dl className="text-xs text-center justify-center block font-normal flex flex-wrap mt-1">
                        <dt className="mr-1">Mobile:</dt>
                        <dd>{order?.MOBILETEL ? <a href={`tel:${order.MOBILETEL}`}>{order.MOBILETEL}</a> : '-'}</dd>
                        <dt className="mr-1 ml-1 border-l border-black border-1 pl-1">Home Tel:</dt>
                        <dd>{order?.HOMETEL ? <a href={`tel:${order.HOMETEL}`}>{order.HOMETEL}</a> : '-'}</dd>
                        <dt className="mr-1 ml-1 border-l border-black border-1 pl-1">Email:</dt>
                        <dd>{order?.E_MAIL ? <a href={`mailto:${order.E_MAIL}`}>{order.E_MAIL}</a> : '-'}</dd>
                    </dl>
                </h1>

            </header>
    )
}
export default Header

//Failed Fit - Installation Managers Report
/*"LINE1": "31 Ormes Lane",
    "LINE2": null,
    "LINE3": null,
    "TOWNCITY": "Wolverhampton",
    "COUNTY": null,
    "POSTCODE": "WV6 8LL",
    "MOBILETEL": "07976391928",
    "HOMETEL": "07853685154",
    "E_MAIL": "Joerebecca@hotmail.co.uk",*/