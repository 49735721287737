import React, { useState } from 'react'
//import { useParams, NavLink, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { format } from 'date-fns' 
import { useDebouncedCallback } from 'use-debounce';
import Reasons from './apr/reasons';

import Debit from './apr/debit';

//import Select from 'react-select';

import Menu from '../menu/menu'
//import Dialog from '../dialog/dialog'

import Button from '../button/large'
import { PlusIcon, CalendarIcon } from '@heroicons/react/outline'
import { SubmissionContext } from '../../../utils/updates'

import './container.css'
import './form.css'
import './reveal-more.css'


import type { Fitter, Order, RemedialDebit, RemedialReason, User } from '../../../utils/types'

type SceneProps = {
    order: Order,
    active: Boolean,
    token: User,
    online: boolean,
    remedialDebit: Array<RemedialDebit>,
    remedialReasons: Array<RemedialReason>,
    fitters: Array<Fitter>
}



const Apr: React.FC<SceneProps> = ({ order, active, token, online, remedialDebit, remedialReasons, fitters, ...props }) => {
    const [ status, setStatus ] = useState<String>('')


    const { register, handleSubmit } = useForm();

    const {
        submission,
        setSubmission
    } = React.useContext(SubmissionContext)


    const bus_first_letter = order.BusLabel === 'Tom Howley' ? 'K' : order.BusLabel[0].toUpperCase();
    
    const settings = {
        debit: remedialDebit,
        reasons: remedialReasons.filter((reason:RemedialReason) => {
            return reason.code[0] === bus_first_letter;
        }),
        fitters: fitters.filter((fitter:Fitter) => {
            return fitter.category.toUpperCase() === bus_first_letter
        })
    }

    
    const sendApr = async (values:any) => {
        //const values = getValues();
        console.log(values);
        setStatus('sending-apr')
        let report = {
            ...order,
            ...values,
            REPORT_RECEIVED: parseInt(values.REPORT_RECEIVED),
            ...submission[order.ORDER_REF]
        }

        const send = await fetch(process.env.REACT_APP_AZURE_FUNCTIONS + 'apr', {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                'flag': (process.env.REACT_APP_VERSION || '')
            }),
            body: JSON.stringify({
                fimid: token.ref,
                fimpassword: token.password_hash,
                report
            })
        }).then(async res => {
            if(res.ok) {
                return await res.json();
            }
            throw res.status;
        })
        .then(() => setStatus('sent'))
        .catch(error => {
            console.log(error)
            setStatus('error')
        })
    }


    const debounced = useDebouncedCallback(
        // function
        (name, value) => {
            setSubmission({
                ...submission,
                [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
            })
        },
        // delay in ms
        700
      );


    return (
        
        <div className={`apr scene ${active ? 'is-active' : ''}`}>
            <form className="scene__form" onSubmit={handleSubmit(sendApr)}>
                <h2 className="row-start-1">Additional Payment / Debit Required</h2>

                <fieldset className="md:col-span-2 border-t pt-4">
                    <legend className="block whitespace-nowrap">Reason for consideration of additional payment</legend>

                    {settings.reasons && (
                    <Reasons 
                        reasons={settings.reasons}
                        register={register}
                        order={order}
                        actions={{
                            submission,
                            setSubmission
                        }}
                    />
                    )}
                    {/*settings.reasons && (
                    <div className="form-field form-field--reveal col-span-12">
                        <ul className="reveal-more" data-depth={reasonDepth}>
                        {
                        [
                            { 
                                code: 'REASON_CODE_1',
                                note: 'REASON_NOTE_1'
                            },
                            {
                                code: 'REASON_CODE_2',
                                note: 'REASON_NOTE_2'
                            }, {
                                code: 'REASON_CODE_3',
                                note: 'REASON_NOTE_3'
                            }, {
                                code: 'REASON_CODE_4',
                                note: 'REASON_NOTE_4'
                            }, {
                                code: 'REASON_CODE_5',
                                note: 'REASON_NOTE_5'
                            }, {
                                code: 'REASON_CODE_6',
                                note: 'REASON_NOTE_6'
                            }, {
                                code: 'REASON_CODE_7',
                                note: 'REASON_NOTE_7'
                            }, {
                                code: 'REASON_CODE_8',
                                note: 'REASON_NOTE_8'
                            }
                        ].map((row:any) => {
                            //const field = watch(debit.code);
                            //const record = field ? settings.debit.find((debit:RemedialDebit) => debit.code === field) : null;

                            //const defaultCode = submission[order.ORDER_REF] ? submission[order.ORDER_REF][debit.code] : 'default'
                            //const defaultNote = submission[order.ORDER_REF] ? submission[order.ORDER_REF][debit.note] : ''

                            let defaultCode: any = null;
                            let defaultCodeLabel = null;
                            let defaultNote = null;
                            if(order[row.code as keyof Order]) {
                                defaultCode = order[row.code as keyof Order]
                                defaultNote = order[row.note as keyof Order]
                            }
                            if(submission[order.ORDER_REF] && submission[order.ORDER_REF][row.code as keyof Order]) {
                                defaultCode = submission[order.ORDER_REF][row.code as keyof Order]
                            }
                            if(submission[order.ORDER_REF] && submission[order.ORDER_REF][row.note as keyof Order]) {
                                defaultNote = submission[order.ORDER_REF][row.note as keyof Order]
                            }

                            if(defaultCode) {
                                let t = settings.reasons.find(reason => {
                                    return reason.id.toString() === defaultCode
                                })
                                defaultCodeLabel = t ? `${t.code} - ${t.reason}` : defaultCodeLabel
                            }

                            return (
                            <li key={row.code}>
                                <div className="select">
                                <Select
                                    defaultValue={defaultCode}
                                    placeholder={defaultCodeLabel || '- Select reason code -'}
                                    options={settings.reasons.map(reason => ({
                                        value: reason.id.toString(),
                                        label: `${reason.code} - ${reason.reason}`
                                    }))}
                                    {...register(row.code)}
                                    className="order-2"
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [row.code]: value}
                                        })
                                    }}
                                />
                                </div>
                                <textarea 
                                    rows={3} 
                                    name={row.note}
                                    disabled={!defaultCode}
                                    placeholder={'Type note'}
                                    onChange={(e) => {
                                        debounced(e.target.name, e.target.value);
                                    }}
                                    defaultValue={defaultNote}
                                />
                            </li>
                        )})
                        }
                        </ul>
                        {reasonDepth < 8 && <button type="button" onClick={() => setReasonDepth(reasonDepth + 1)}><PlusIcon />Add another</button>}
                    </div>
                    )*/}
                </fieldset>
                <fieldset className="md:col-span-2 md:col-start-1 mt-4">
                    <div className="form-field form-field--alternate md:col-span-12">
                        <input 
                            type="number" 
                            className="text-right flex-1!" 
                            placeholder="0.00" 
                            step="0.01"
                            name={'AMOUNT_CONSIDERED'}
                            //pattern="[0-9]+([,\.][0-9]+)?"
                            defaultValue={submission[order.ORDER_REF]?.AMOUNT_CONSIDERED || order.AMOUNT_CONSIDERED} 
                            onChange={({ target: { name, value, valueAsNumber, validity: { valid, patternMismatch } }, ...props }) => {
                                if(!valid) { return; }
                                //const clone = {...submission}
                                //const ref = submission[order.ORDER_REF];
    
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: valueAsNumber}
                                })
                            }}
                        />
                        <label htmlFor="AMOUNT_CONSIDERED">Amount to be considered by head of external operations (&pound;)</label>
                    </div>

                    <div className="form-field form-field--alternate form-field--accountancy col-span-12">
                        <input 
                            type="text" 
                            id="FITTER1" 
                            {...register('FITTER1')}
                            readOnly
                            value={submission[order.ORDER_REF]?.FITTER1 || order.FITTER1} 
                            /*onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }    
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                })
                            }}*/
                        />
                        <label htmlFor="FITTER1">Name of fitter 1</label>
                    </div>

                    <div className="form-field form-field--alternate form-field--accountancy col-span-12">
                        <input 
                            type="text" 
                            id="FITTER2" 
                            {...register('FITTER2')}
                            readOnly
                            value={submission[order.ORDER_REF]?.FITTER2 || order.FITTER2} 
                            /*onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }    
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                })
                            }}*/
                        />
                        <label htmlFor="FITTER2">Name of fitter 2</label>
                    </div>

                    {settings.fitters && settings.fitters.length > 0 && (
                    <div className="form-field form-field--alternate form-field--select col-span-12" style={{zIndex: 500}}>
                        
                        <div className="select">
                            {/*
                                <Select
                                    defaultValue={submission[order.ORDER_REF]?.FITTER_ACTUAL || order?.FITTER_ACTUAL}
                                    //placeholder={submission[order.ORDER_REF]?.FITTER_ACTUAL || order?.FITTER_ACTUAL || '- Select -'}
                                    placeholder={settings.fitters.find(fitter => {
                                        let selected = submission[order.ORDER_REF]?.FITTER_ACTUAL || order?.FITTER_ACTUAL;
                                        return fitter.id.toString() === selected
                                    })?.name || '- Select -'}
                                    options={settings.fitters.sort((a,b)=> a.name < b.name ? -1:1).map(fitter => ({
                                        value: fitter.id.toString(),
                                        label: fitter.name
                                    }))}
                                    {...register('FITTER_ACTUAL')}
                                    className="order-2"
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'FITTER_ACTUAL': value}
                                        })
                                    }}
                                />*/}
                                <Menu
                                    hot={submission[order.ORDER_REF]?.FITTER_ACTUAL}
                                    fallback={order?.FITTER_ACTUAL}
                                    options={settings.fitters.sort((a,b)=> a.name < b.name ? -1:1).map(fitter => ({
                                        value: fitter.id.toString(),
                                        label: fitter.name
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'FITTER_ACTUAL': ''}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'FITTER_ACTUAL': value}
                                        })
                                    }}
                                />
                        </div>
                        <label htmlFor="FITTER_ACTUAL">Alternative fitter</label>
                    </div>
                        )}


                    <div className="form-field form-field--alternate form-field--radios col-span-12">
                        <h3>Is this amount for one fitter or a two-man team?</h3>
                        <ul>
                            <li>
                                <input 
                                    type="radio"
                                    id="FITTER_TEAM_1" 
                                    name="FITTER_TEAM" 
                                    value={1}
                                    checked={submission[order.ORDER_REF]?.FITTER_TEAM === 1 || order.FITTER_TEAM === 1} 
                                    onChange={({ target: { name, checked, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }    
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 1}
                                        })
                                    }}
                                />
                                <label htmlFor="FITTER_TEAM_1">One</label>
                            </li>
                            <li>
                                <input 
                                    type="radio" 
                                    id="FITTER_TEAM_2" 
                                    name="FITTER_TEAM" 
                                    value={2} 
                                    checked={submission[order.ORDER_REF]?.FITTER_TEAM === 2 || order.FITTER_TEAM === 2} 
                                    onChange={({ target: { name, checked, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }    
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 2}
                                        })
                                    }}
                                />
                                <label htmlFor="FITTER_TEAM_2">Two</label>
                            </li>
                        </ul>
                    </div>

                </fieldset>

                <fieldset className="md:col-span-2 border-t pt-4 mt-6">
                    <legend className="block whitespace-nowrap">Debit section</legend>
                    <div className="form-field col-span-12 -mt-2">
                    <p className="text-zinc-600 dark:text-zinc-300">This section is for debiting a Fitter / Painter when their actions have cost the company due to error.</p>
                    </div>
                    {settings.debit && (
                        <Debit
                            reasons={settings.debit}
                            register={register}
                            order={order}
                            actions={{
                                submission,
                                setSubmission
                            }}
                        />
                    )}
                    {/*settings.debit && (
                    <div className="form-field form-field--reveal col-span-12">
                        <ul className="reveal-more" data-depth={debitDepth}>
                        {
                        [
                            { 
                                code: 'DEBIT_CODE_1',
                                note: 'DEBIT_NOTE_1'
                            },
                            {
                                code: 'DEBIT_CODE_2',
                                note: 'DEBIT_NOTE_2',
                            }, {
                                code: 'DEBIT_CODE_3',
                                note: 'DEBIT_NOTE_3'
                            }
                        ].map((row:any) => {
                            //const field = watch(debit.code);
                            //const record = field ? settings.debit.find((debit:RemedialDebit) => debit.code === field) : null;

                            //const defaultCode = submission[order.ORDER_REF] ? submission[order.ORDER_REF][debit.code] : 'default'
                            //const defaultNote = submission[order.ORDER_REF] ? submission[order.ORDER_REF][debit.note] : ''

                            let defaultCode: any = null;
                            let defaultCodeLabel = null;
                            let defaultNote = null;
                            
                            if(order[row.code as keyof Order]) {
                                defaultCode = order[row.code as keyof Order]
                                defaultNote = order[row.note as keyof Order]
                            }
                            if(submission[order.ORDER_REF] && submission[order.ORDER_REF][row.code as keyof Order]) {
                                defaultCode = submission[order.ORDER_REF][row.code as keyof Order]
                            }
                            if(submission[order.ORDER_REF] && submission[order.ORDER_REF][row.note as keyof Order]) {
                                defaultNote = submission[order.ORDER_REF][row.note as keyof Order]
                            }

                            if(defaultCode) {
                                let t = settings.debit.find(setting => {
                                    return setting.id.toString() === defaultCode
                                })
                                defaultCodeLabel = t ? `${t.code} - ${t.reason}` : defaultCodeLabel
                            }

                            return (
                            <li key={row.code}>
                            <div className="select">
                                    <Select
                                        defaultValue={defaultCode}
                                        placeholder={defaultCodeLabel || '- Select debit code -'}
                                        options={settings.debit.map((setting, i) => ({
                                            value: setting.id.toString(),
                                            label: `${setting.code} - ${setting.reason}`
                                        }))}
                                        {...register(row.code)}
                                        className="order-2"
                                        classNamePrefix={'fim'}
                                        isSearchable={false}
                                        onChange={({ value }) => {
                                            setSubmission({
                                                ...submission,
                                                [order.ORDER_REF]: {...submission[order.ORDER_REF], [row.code]: value}
                                            })
                                        }}
                                    />
                                </div>
                                <textarea 
                                    rows={3} 
                                    name={row.note}
                                    disabled={!defaultCode}
                                    placeholder={'Type note'}
                                    onChange={(e) => {
                                        debounced(e.target.name, e.target.value);
                                    }}
                                    defaultValue={defaultNote}
                                />
                            </li>
                        )})
                        }
                        </ul>
                        {debitDepth < 3 && <button type="button" onClick={() => setDebitDepth(debitDepth + 1)}><PlusIcon />Add another</button>}
                    </div>
                    )*/}

                    
                </fieldset>

                <fieldset className="md:col-span-2 border-t pt-4 mt-4">
                    <legend className="block whitespace-nowrap">For internal office use</legend>

                    <div className="form-field form-field--radios col-span-12">
                        <h3>Have we received a fitters report for this job?</h3>
                        <ul>
                            <li>
                                <input 
                                    type="radio" 
                                    id="REPORT_RECEIVED__yes" 
                                    value="1" 
                                    required
                                    {...register('REPORT_RECEIVED', {
                                        required: true
                                    })} 
                                    defaultChecked={submission[order.ORDER_REF]?.REPORT_RECEIVED === 1 || order.REPORT_RECEIVED === 1} 
                                    onChange={({ target: { name, checked, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }    
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 1}
                                        })
                                    }}
                                />
                                <label htmlFor="REPORT_RECEIVED__yes">Yes</label>
                            </li>
                            <li>
                                <input 
                                    type="radio" 
                                    id="REPORT_RECEIVED__no" 
                                    value="0" 
                                    required
                                    {...register('REPORT_RECEIVED', {
                                        required: true
                                    })} 
                                    defaultChecked={submission[order.ORDER_REF]?.REPORT_RECEIVED === 0 || order.REPORT_RECEIVED === 0} 
                                    onChange={({ target: { name, checked, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }    
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 0}
                                        })
                                    }}
                                />
                                <label htmlFor="REPORT_RECEIVED__no">No</label>
                            </li>
                        </ul>
                    </div>
                    <div className="form-field col-span-12">
                    <p className="text-zinc-600 dark:text-zinc-300">If no, then this APR is not to be processed, until the report is received. If no report is received within 7 days of this this request, then a £50 fine is to be implemented and recorded on the Fitters Penalty log record sheets.</p>
                    </div>

                    <div className="form-field col-span-12 mt-4 gap-x-6">
                        <div className="flex flex-col flex-1 gap-y-2">
                            <input {...register('AUTHORISED_BY', {
                                required: true
                            })} required type="text" id="AUTHORISED_BY" defaultValue={order.AUTHORISED_BY} />
                            <label htmlFor="AUTHORISED_BY">Authorised by</label>
                        </div>
                        <div className="flex gap-y-2 items-center self-end py-2 gap-x-6 h-full">
                        <div className="calendar order-2">
                            <input type="date" id="SYNCH_DATE" disabled defaultValue={format(new Date(), 'yyyy-MM-dd')} />
                            <CalendarIcon />
                        </div>
                            <label className="order-2 ml-4" htmlFor="AUTHORISED_BY">Date</label>
                        </div>
                    </div>
                </fieldset>


                <fieldset className="md:col-span-2 max-w-4xl w-full mx-auto">
                <div className="form-field mt-10 col-span-12">
                    {online === true && <Button disabled={status === 'sending-apr'} type="submit">{status === 'sent' ? 'APR Sent' : 'Send APR'}</Button>}
                    {online === false && <p className="text-center text-neutral-500 text-lg font-semibold antialiased mx-auto bg-neutral-300 py-3 px-6 rounded">Connect to wifi send APR</p>}
                </div>
            </fieldset>
            </form>
        </div>
    )
}
export default Apr
