import React from 'react'

import './circle.css'

type SpinnerProps = {
    
}

const Spinner : React.FC<SpinnerProps> = ({...props}) => (
    <svg className="spinner" viewBox="0 0 50 50" {...props}>
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
)
export default Spinner