import React from 'react'


import './search.css'

type SearchProps = {
    display: Boolean,
    callback: Function
}

type InputProps = {
    target: {
        value: String,
        name: String
    }
}

const Search : React.FC<SearchProps> = ({ display, callback, ...props }) => {
    //const [ open, setOpen ] = useState<boolean>(false);


    return (
        <form method="get" className={[(display ? 'opacity-100 z-10' : 'opacity-0'), `search-box`].join(' ')}>
            <fieldset>
                <input type="search" placeholder="Search by client name..." name="client" onChange={((data:InputProps) => {
                    callback({ name: data.target.name, value: data.target.value });
                })} />
                <input type="search" placeholder="Search by post code..." name="postcode" onChange={((data:InputProps) => {
                    callback({ name: data.target.name, value: data.target.value });
                })} />
            </fieldset>
        </form>
    )
}

export default Search