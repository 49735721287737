import React, { useState } from 'react'
//import { useParams, NavLink, Link } from 'react-router-dom'
//import Spinner from '../spinner/circle'
import Client from './client'
import Report from './report'
import Apr from './apr'
import './container.css'


import type { Order, User } from '../../../utils/types'



type SwiperProps = {
    order: Order,
    token: User,
    online: boolean,
    settings: any
}


const App: React.FC<SwiperProps> = ({ order, token, online, settings, ...props }) => {
    const [ activeSlide, setActiveSlide ] = useState(process.env.NODE_ENV === 'development' ? 1 : 0);

    const isTomHowley = order.BUS_ID.toString() === '2';

    return (

        <main className="w-full">

        <Nav active={activeSlide} isTomHowley={isTomHowley} callback={setActiveSlide} />
        <div className="scenes">
            <Client order={order} active={activeSlide === 0} remedialStatuses={settings.remedialStatuses} />
            <Report
                order={order}
                active={activeSlide === 1}
                token={token}
                online={online}
                remedialCategories={settings.remedialCategories}
                remedialSubcategories={settings.remedialSubcategories}
                remedialReasons={settings.remedialReasons}
                remedialCauses={settings.remedialCauses}
                remedialProcessingPriority={settings.remedial_processing_priority}
            />
            {isTomHowley === false ? (<Apr
                order={order}
                active={activeSlide === 2}
                token={token}
                online={online}
                remedialDebit={settings.remedialDebit}
                remedialReasons={settings.remedialReasons}
                fitters={settings.fitters}
            />) : null}

        </div>
    </main>
    )
}
export default App


type NavProps = {
    active: Number,
    callback: Function,
    isTomHowley: boolean
}

const Nav: React.FC<NavProps> = ({ active, isTomHowley, callback, ...props }) => {
    let arr = ['client', 'report' ]
    if (isTomHowley === false) {
        arr = [...arr, 'apr']
    }

    return (
    <nav className="swiper-links z-20 sticky top-0 dark:bg-zinc-800 flex gap-5 px-6 md:px-10  w-full order-1 font-semibold antialiased bg-white shadow-lg text-sm uppercase">
                {arr.map((slide, i) => (
                    <button
                        key={i}
                        type="button"
                        disabled={active === i}
                        onClick={() => callback(i)}
                        className="px-6 md:px-10 py-4 flex flex-1 uppercase font-semibold justify-center">{slide}</button>
                ))}
            </nav>
    )
}