import React from 'react'
//import { useParams, NavLink, Link } from 'react-router-dom'
//import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
//import { useIndexedDBStore } from 'use-indexeddb'
import { SubmissionContext } from '../../../utils/updates'
import { useDebouncedCallback } from 'use-debounce'
import Comment from '../comments'
import Menu from '../menu/menu'

import { CalendarIcon,  } from '@heroicons/react/outline'
import { ExclamationIcon } from '@heroicons/react/solid'

import './container.css'
import './form.css'


import type { Order, RemedialStatus } from '../../../utils/types'

type SceneProps = {
    order: Order,
    active: Boolean,
    remedialStatuses: Array<RemedialStatus>
}

const currency_formatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });


const Client: React.FC<SceneProps> = ({ order, active, remedialStatuses, ...props }) => {
    const FIT_WEEK = format(new Date(order.FIT_WEEK), 'yyyy-MM-dd');
    const INSPECTION_VISIT = order.INSPECTION_VISIT ? format(new Date(order.INSPECTION_VISIT), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');

    const {
        submission,
        setSubmission
    } = React.useContext(SubmissionContext)

    const inspection_status = remedialStatuses.find((status:RemedialStatus) => status.id.toString() === order.INSPECTION_STATUS);
    const status: any = inspection_status?.status || '';



    const debounced = useDebouncedCallback(
        // function
        (name, value) => {
            //callback({ field, value });
            //const clone = {...submission}
            //const ref = submission[order.ORDER_REF];

            setSubmission({
                ...submission,
                [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
            })
        },
        // delay in ms
        300
      );

    return (

        <div className={`client scene ${active ? 'is-active' : ''}`}>
            {order.FINANCE_NOTES && (
                <div className="alert">
                    <ExclamationIcon />
                    <p>{order.FINANCE_NOTES}</p>
                </div>
            )}
            <form className="scene__form">
                <fieldset className="md:col-span-1 md:row-start-1">
                    <div className="form-field col-span-12">
                        { status && <input type="text" readOnly value={status} /> }
                        <label htmlFor="order-type">Order Type</label>
                    </div>
                    <div className="form-field col-span-12">
                        <input type="text" readOnly value={order.NO_AREAS_DEFAULT} />
                        <label htmlFor="order-type">No of areas</label>
                    </div>
                    <div className="form-field form-field--date col-span-12">
                        <div className="input disabled">
                            <input type="date" id="FIT_WEEK" disabled defaultValue={FIT_WEEK} />
                            <CalendarIcon />
                        </div>
                        <label htmlFor="FIT_WEEK">Fit date (start)</label>
                    </div>
                    <div className="form-field form-field--week col-span-12">
                        <ul>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="MON_HRS"
                                    name="MON_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.MON_HRS || (order.MON_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="MON_HRS">M</label>
                            </li>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="TUE_HRS"
                                    name="TUE_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.TUE_HRS || (order.TUE_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="TUE_HRS">T</label>
                            </li>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="WED_HRS"
                                    name="WED_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.WED_HRS || (order.WED_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="WED_HRS">W</label>
                            </li>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="THU_HRS"
                                    name="THU_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.THU_HRS || (order.THU_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="THU_HRS">T</label>
                            </li>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="FRI_HRS"
                                    name="FRI_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.FRI_HRS || (order.FRI_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="FRI_HRS">F</label>
                            </li>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="SAT_HRS"
                                    name="SAT_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.SAT_HRS || (order.SAT_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="SAT_HRS">S</label>
                            </li>
                            <li>
                                <input
                                    min="0"
                                    type="number"
                                    id="SUN_HRS"
                                    name="SUN_HRS"
                                    defaultValue={submission[order.ORDER_REF]?.SUN_HRS || (order.SUN_HRS || '')}
                                    onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        debounced(name, valueAsNumber)
                                    }}
                                />
                                <label htmlFor="SUN_HRS">S</label>
                            </li>
                        </ul>
                    </div>
                    <div className="form-field col-span-12">
                        <input type="text" id="FITTER1" readOnly value={order.FITTER1 || ''} />
                        <label htmlFor="FITTER1">Fitter 1</label>
                    </div>
                    <div className="form-field col-span-12">
                        <input type="text" id="FITTER2" readOnly value={order.FITTER2 || ''} />
                        <label htmlFor="FITTER2">Fitter 2</label>
                    </div>
                    <div className="form-field col-span-12">
                        <input type="text" id="CUS_SERV" readOnly value={order.CUS_SERV || ''} />
                        <label htmlFor="CUS_SERV">CS Co-ordinator</label>
                    </div>
                    <div className="form-field col-span-12">
                        <input type="text" id="DO_FPW" readOnly value={order.DO_FPW || ''} />
                        <label htmlFor="DO_FPW">DO FPW</label>
                    </div>

                    <div className="form-field form-field--date col-span-12">
                        <div className="input">
                            <input
                                type="date"
                                id="INSPECTION_VISIT"
                                defaultValue={INSPECTION_VISIT}
                                name="INSPECTION_VISIT"
                                className="appearance-none"
                                onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                    if(!valid) { return; }

                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                    })
                                }}
                            />
                            <CalendarIcon />
                        </div>
                        <label htmlFor="INSPECTION_VISIT">Date of visit</label>
                    </div>

                    <div className="form-field form-field--select col-span-12">
                        {remedialStatuses && (
                        <div className="select">

                            <Menu
                                hot={submission[order.ORDER_REF]?.INSPECTION_STATUS}
                                fallback={order?.INSPECTION_STATUS}
                                options={remedialStatuses.map(status => ({
                                    value: status.id.toString(),
                                    label: status.status
                                }))}
                                onClear={() => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INSPECTION_STATUS': ''}
                                    })
                                }}
                                onSelect={(value:string) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INSPECTION_STATUS': value}
                                    })
                                }}
                            />
                            {/*<Select
                                placeholder={remedialStatuses.find(status => {
                                    let selected = submission[order.ORDER_REF]?.INSPECTION_STATUS || order.INSPECTION_STATUS
                                    return status.id.toString() === selected
                                })?.status || '- Select -'}
                                defaultValue={submission[order.ORDER_REF]?.INSPECTION_STATUS || order.INSPECTION_STATUS || '- Select -'}
                                options={remedialStatuses.map(status => ({
                                    value: status.id.toString(),
                                    label: status.status
                                }))}
                                className="order-2"
                                classNamePrefix="fim"
                                isSearchable={false}
                                styles={customStyles}
                                onChange={({ value }) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INSPECTION_STATUS': value}
                                    })
                                }}
                            />*/}
                        {/*<select
                            name="INSPECTION_STATUS"
                            id="INSPECTION_STATUS"
                            className="order-2"
                            value={submission[order.ORDER_REF]?.INSPECTION_STATUS || order.INSPECTION_STATUS}
                            onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }
                                //const clone = {...submission}
                                //const ref = submission[order.ORDER_REF];

                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                })
                            }}
                            >
                            {remedialStatuses.map(status => (
                                <option
                                    //selected={status.id.toString() === order.INSPECTION_STATUS}
                                    value={status.id.toString()} key={status.id.toString()}>{status.status}</option>
                            ))}
                            </select>*/}
                        </div>
                        )}
                        <label htmlFor="INSPECTION_STATUS">Inspection request</label>
                    </div>

                    <div className="form-field form-field--radios col-span-12">
                        <h3>Fit complete</h3>
                        <ul>
                            <li>
                                <input type="radio" id="" value={1} readOnly name="INSTALLATION_COMPLETE" checked={order.INSTALLATION_COMPLETE === 1} />
                                <label htmlFor="">Yes</label>
                            </li>
                            <li>
                                <input type="radio" id="" value={0} readOnly name="INSTALLATION_COMPLETE" checked={order.INSTALLATION_COMPLETE === 0} />
                                <label htmlFor="">No</label>
                            </li>
                        </ul>
                    </div>
                </fieldset>

                <fieldset className="md:col-span-1 md:row-start-2">
                    <Comment
                        field={'FIM_APP_ORDER_COMMENT'}
                        label={'Install update'}
                        defaultValue={submission[order.ORDER_REF]?.FIM_APP_ORDER_COMMENT || ''}
                        previousValue={order.ORDER_COMMENT_DEFAULT}
                        callback={(update:any) => {
                            //const clone = {...submission}
                            //const ref = submission[order.ORDER_REF];

                            setSubmission({
                                ...submission,
                                [order.ORDER_REF]: {...submission[order.ORDER_REF], [update.field]: update.value}
                            })
                        }}
                        {...{className: "form-field form-field--textarea col-span-12 mt-10"}}
                    />
                </fieldset>
                <fieldset className="md:col-span-1 md:row-start-1">

                    <div className="form-field col-span-12">
                        <input type="text" readOnly value={order.DEBT_STATUS} />
                        <label htmlFor="DEBT_STATUS">Debt Status</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly value={currency_formatter.format(parseFloat(order.ORDER_VALUE_GROSS))} />
                        <label htmlFor="ORDER_VALUE_GROSS">Order value (&pound;)</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly value={currency_formatter.format(parseFloat(order.TOTAL_DEPOSIT))} />
                        <label htmlFor="TOTAL_DEPOSIT">Total deposit (&pound;)</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly value={currency_formatter.format(parseFloat(order.AC_BAL))} />
                        <label htmlFor="AC_BAL">Account balance (&pound;)</label>
                    </div>

                    <div className="form-field col-span-12">
                        <input
                            type="number"
                            className="text-right"
                            placeholder="0.00"
                            step="0.01"
                            name={'FIM_APP_COLLECTED'}
                            pattern="[0-9]+([,\.][0-9]+)?"
                            defaultValue={submission[order.ORDER_REF]?.FIM_APP_COLLECTED || order.FIM_APP_COLLECTED}
                            onChange={({ target: { name, value, valueAsNumber, validity: { valid, patternMismatch } }, ...props }) => {
                                if(!valid) { return; }
                                debounced(name, valueAsNumber)
                            }}
                        />
                        <label htmlFor="FIM_APP_COLLECTED">Collected (&pound;)</label>
                    </div>
                    <div className="form-field col-span-12">
                        <input
                            type="number"
                            className="text-right"
                            placeholder="0.00"
                            name={'FIM_APP_PC_PAID'}
                            pattern="[0-9]+([,\.][0-9]+)?"
                            defaultValue={submission[order.ORDER_REF]?.FIM_APP_PC_PAID || order.FIM_APP_PC_PAID}
                            onChange={({ target: { name, value, valueAsNumber, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }
                                debounced(name, valueAsNumber)
                            }}
                        />
                        <label htmlFor="FIM_APP_PC_PAID">Paid to fitter (&pound;)</label>
                    </div>


                    <div className="form-field col-span-12">
                        <h3>Invoice date</h3>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly defaultValue={currency_formatter.format(parseFloat(order.THIRTY))} />
                        <label htmlFor="THIRTY">0 to 30 days (&pound;)</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly defaultValue={currency_formatter.format(parseFloat(order.SIXTY))} />
                        <label htmlFor="SIXTY">31 to 60 days (&pound;)</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly defaultValue={currency_formatter.format(parseFloat(order.NINETY))} />
                        <label htmlFor="NINETY">61 to 90 days (&pound;)</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly defaultValue={currency_formatter.format(parseFloat(order.ONE_TWENTY))} />
                        <label htmlFor="ONE_TWENTY">91 to 120 days (&pound;)</label>
                    </div>
                    <div className="form-field form-field--accountancy col-span-12">
                        <input type="text" readOnly defaultValue={currency_formatter.format(parseFloat(order.OVER_ONE_TWENTY))} />
                        <label htmlFor="OVER_ONE_TWENTY">+120 days (&pound;)</label>
                    </div>
                </fieldset>

                <fieldset className="md:col-span-1 md:row-start-2">

                    <Comment
                        field={'FIM_APP_ACCOUNT_COMMENT'}
                        label={'Account comment'}
                        defaultValue={submission[order.ORDER_REF]?.FIM_APP_ACCOUNT_COMMENT || ''}
                        previousValue={order.ACCOUNT_COMMENT_DEFAULT}
                        callback={(update:any) => {
                            //const clone = {...submission}
                            //const ref = submission[order.ORDER_REF];

                            setSubmission({
                                ...submission,
                                [order.ORDER_REF]: {...submission[order.ORDER_REF], [update.field]: update.value}
                            })
                        }}
                        {...{className: "form-field form-field--textarea col-span-12 mt-10"}}
                    />

                </fieldset>


                <fieldset className="md:col-span-1 md:col-start-2 md:row-start-3">
                    <ul className="form-field form-field--flags">
                        {['BALANCE_FLAG', 'NEW_FLAG', 'REMEDIAL_FLAG', 'INSPECTION_FLAG'].map(flag => (
                            <li key={flag}>
                                <label htmlFor={flag} className="relative">
                                    <input className="block w-5 h-5 mx-auto" type="radio" defaultValue={flag} readOnly id={flag} checked={order[flag as keyof Order] === 1} />
                                    <span className="mt-2 leading-none block">{flag.replace('_FLAG',' ')}</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </fieldset>
            </form>
        </div>
    )
}
export default Client
