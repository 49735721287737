import React, { useState,  } from 'react'

//import ClickOutHandler from 'react-onclickout';
//import { DialogOption } from '../../../utils/types'
import { useKeypress } from '../../../utils/custom-hooks'

import './menu.css'
import { BackspaceIcon } from '@heroicons/react/outline';

type Option = {
    value: string,
    label: String
}

type MenuProps = {
    hot: string,
    fallback: string,
    options: Array<Option>,
    onSelect: Function,
    onClear: Function,
    defaultLabel?: string,
    ariaLabel?: string
}

const chevron = <svg height="20" width="20" viewBox="0 0 20 20" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>


const Menu : React.FC<MenuProps> = ({ hot, fallback, options, onSelect, onClear, defaultLabel = '- Select -', ariaLabel, ...props }) => {
    const [ open, setOpen ] = useState<boolean>(false);

    useKeypress('Escape', () => {
        setOpen(false)
    });

    const selected = hot || hot === '' ? hot?.toString() : fallback?.toString();

    const selectedLabel = selected ? options.find(({ value }) => value.toString() === selected)?.label : null;

    return (
        <div className="menu" {...props}>
            <div className="menu__field">
            {selected ? (
                <>
                <button className="menu__trigger" type="button" onClick={() => setOpen(!open)} data-value={selected}>
                    <span>{selectedLabel}</span>
                    {chevron}
                </button>
                <button className="menu__clear" type="button" onClick={() => {
                    onClear()
                    setOpen(false)
                }}>
                    <BackspaceIcon />
                </button>
                </>
            ) : (
                <button className="menu__trigger" type="button" onClick={() => setOpen(!open)}>
                    <span>{ defaultLabel }</span>
                    {chevron}
                </button>
            )}
            </div>
            {open && (
                <div className="menu__dropdown">
                    <ul>
                        {options.map(option => (
                            <li key={option.value}>
                                <button type="button" aria-label={ariaLabel} disabled={option.value === selected} onClick={() => {
                                    onSelect(option.value)
                                    setOpen(false)
                                }}>
                                    {option.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default Menu