import React, { useState, useEffect } from 'react'
import { useNavigate} from "react-router-dom";
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useLocalStorage } from '../../utils/custom-hooks';
import { useIndexedDBStore } from 'use-indexeddb'

import Button from '../components/button/large'
import Spinner from '../components/spinner/circle'

import { PlusIcon } from '@heroicons/react/solid'
//import Progress from '../components/progress'

import type { RemedialCategory, RemedialStatus, RemedialDebit, RemedialReason, RemedialCause, RemedialProcessingPriority, Fitter } from '../../utils/types';

import '../components/login/login.css'



const Scene = () => {
    const [ token, setToken ] = useLocalStorage(`user__${process.env.REACT_APP_VERSION}`, null);
    const [ , setOrders ] = useLocalStorage(`orders__${process.env.REACT_APP_VERSION}`, []);
    const { register, handleSubmit, formState: { errors } }: any = useForm();
    const [ online, setOnline ] = useState(false);
    const [ status, setStatus ] = useState<string>('')

    const { update:upsertToRemedialCategories } = useIndexedDBStore("remedial_categories");
    const { update:upsertToRemedialSubCategories } = useIndexedDBStore("remedial_subcategories");
    const { update:upsertToRemedialStatuses } = useIndexedDBStore("remedial_statuses");
    const { update:upsertToRemedialDebit } = useIndexedDBStore("remedial_debit");
    const { update:upsertToRemedialReasons } = useIndexedDBStore("remedial_reasons");
    const { update:upsertToRemedialCauses } = useIndexedDBStore("remedial_causes");
    const { update:upsertToFitters } = useIndexedDBStore("fitters");
    const { update:upsertToRemedialProcessingPriority } = useIndexedDBStore("remedial_processing_priority");


    let navigate = useNavigate();

    useEffect(() => {
        setOnline(window.navigator.onLine);
        window.addEventListener('offline', function(e) { setOnline(false); });
        window.addEventListener('online', function(e) { setOnline(true); });
    }, []);


    const onSubmit = handleSubmit(async (data: any) => {
        setStatus('sending')

        const token = await fetch(process.env.REACT_APP_AZURE_FUNCTIONS + 'login', {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                'flag': (process.env.REACT_APP_VERSION || '')
            }),
            body: JSON.stringify({
                ...data
            })
        }).then(async res => {
            if(res.ok) {
                return await res.json();
            }
            throw res.status;
        })
        .catch(error => {
            console.log({
                error
            });
        })

        if(!token) {
            setStatus('error')
            return;
        }

        // setup app
        setStatus('booting')
        await fetch(process.env.REACT_APP_AZURE_FUNCTIONS + 'settings', {
            method: 'GET',
            headers: new Headers({
                'content-type': 'application/json',
                'flag': (process.env.REACT_APP_VERSION || '')
            }),

          }).then(async res => {
            if(res.ok) {
              const {
                remedial_categories,
                remedial_subcategories,
                remedial_statuses,
                remedial_debit,
                remedial_reasons,
                fitters,
                remedial_processing_priority,
                remedial_causes
              } = await res.json()

              const insert_remedial_categories = await Promise.all(remedial_categories.map(async (record:RemedialCategory) => {
                return await upsertToRemedialCategories(record);
              }))

              const insert_remedial_subcategories = await Promise.all(remedial_subcategories.map(async (record:RemedialCategory) => {
                return await upsertToRemedialSubCategories(record);
              }))

              const insert_remedial_statuses = await Promise.all(remedial_statuses.map(async (record:RemedialStatus) => {
                return await upsertToRemedialStatuses(record);
              }))

              const insert_remedial_debit = await Promise.all(remedial_debit.map(async (record:RemedialDebit) => {
                return await upsertToRemedialDebit(record);
              }))

              const insert_remedial_reasons = await Promise.all(remedial_reasons.map(async (record:RemedialReason) => {
                return await upsertToRemedialReasons(record);
              }))
              const insert_fitters = await Promise.all(fitters.map(async (record:Fitter) => {
                return await upsertToFitters(record);
              }))

              const insert_remedial_processing_priority = await Promise.all(remedial_processing_priority.map(async (record:RemedialProcessingPriority) => {
                return await upsertToRemedialProcessingPriority(record);
              }))

              const insert_remedial_causes = await Promise.all(remedial_causes.map(async (record:RemedialCause) => {
                return await upsertToRemedialCauses(record);
              }))

              return {
                insert_remedial_categories,
                insert_remedial_subcategories,
                insert_remedial_statuses,
                insert_remedial_debit,
                insert_remedial_reasons,
                insert_remedial_causes,
                insert_fitters,
                insert_remedial_processing_priority
              }
            }
          })

        setToken(token.user);
    })

    useEffect(() => {
        if(token) {
            setStatus('fetching-orders')
            fetch(process.env.REACT_APP_AZURE_FUNCTIONS + `orders?uid=${token.ref}&token=${token.password_hash}`, {
                method: 'GET',
                headers: new Headers({
                    'content-type': 'application/json',
                    'flag': (process.env.REACT_APP_VERSION || '')
                }),
            }).then(async res => {
                if(res.ok) {
                    return await res.json();
                }
                throw res.status;
            }).then(({ orders }) => {
                setOrders(orders)
            })
            .finally(() => {
                navigate(`/${process.env.REACT_APP_VERSION}/dashboard`)
                setStatus('done')
            })
        }
    }, [token ]);

    let btn_lbl = status === 'sending' ? 'Authenticating...' : 'Log in'
    btn_lbl = status === 'booting' ? 'Fetching settings...' : btn_lbl
    btn_lbl = status === 'fetching-orders' ? 'Fetching your orders...' : btn_lbl

    return (
        <div className="mx-auto bg-black dark:bg-zinc-800 px-4 min-h-screen flex justify-center items-center gap-y-10 flex-col relative">
            <Helmet title="Log in" defer={false}>

            </Helmet>

            <div className={[process.env.NODE_ENV === 'production' ? 'app:hidden' : '', `app:hidden mx-auto text-center text-white flex flex-col gap-y-4 items-center text-xl md:text-2xl w-full max-w-md antialiased mx-auto`].join(' ')}>
                <PlusIcon className="w-12 h-12 text-white border border-white border-2 rounded" />
                Add to homescreen to install
                <small className="block -mt-4 text-sm">Version {process.env.REACT_APP_VERSION}</small>
            </div>



            {['sending', 'booting', 'fetching-orders'].includes(status) && (
                <div className="loading">
                    <Spinner />
                    {status === 'sending' && <span>Authenticating your details&hellip;</span>}
                    {status === 'booting' && <span>Preparing app&hellip;</span>}
                    {status === 'fetching-orders' && <span>Fetching your orders&hellip;</span>}
                </div>
            )}
            <form className={[process.env.NODE_ENV === 'production' ? 'hidden app:block' : '', 'log-in'].join(' ')} onSubmit={onSubmit}>
                <legend>Please login</legend>

                {status === 'error' && <div className="text-red mb-4 text-center"><span>Incorrect log in details, please try again.</span></div>}
                <ul className="flex flex-col gap-y-5 mb-6">
                    <li className="flex gap-x-2 items-center">
                        <input
                        type="text"
                        //tw="placeholder:color[#959595]"
                        className="rounded px-3 border border-neutral-500 py-3 appearance-none text-left bg-transparent text-white outline-none w-full text-base order-2"
                        {...register('username', {
                            required: 'Please enter a username'
                        })}
                        id={'username'}
                        inputMode="text"
                        placeholder="Your username"
                        disabled={['sending', 'booting', 'fetching-orders'].includes(status)}
                        defaultValue={process.env.NODE_ENV === 'development' ? 'joe' : ''}
                        />
                        <label className="order-1 sr-only" htmlFor="username">Username</label>
                    </li>
                    <li className="flex gap-x-2 items-center">
                        <input
                            type="password"
                            //tw="placeholder:color[#959595]"
                            className="rounded border border-neutral-500 py-3 px-3 appearance-none text-left bg-transparent text-white outline-none w-full text-base order-2"
                            {...register('password', {
                                required: 'Please enter a password'
                            })}
                            id={'password'}
                            inputMode="text"
                            autoComplete='current-password'
                            placeholder="Your password"
                            disabled={['sending', 'booting', 'fetching-orders'].includes(status)}
                            defaultValue={process.env.NODE_ENV === 'development' ? 'joe' : ''}
                        />
                        <label className="order-1 sr-only" htmlFor="password">Password</label>
                    </li>
                    </ul>

                    {errors?.username && <p className="text-sm text-red -mt-2 mb-4">{errors.username.message}</p>}
                    {errors?.password && <p className="text-sm text-red -mt-2 mb-4">{errors.password.message}</p>}


        <Button disabled={!online || ['sending', 'booting', 'fetching-orders'].includes(status)} type="submit">
            {online ? btn_lbl : 'Please connect to login'}
        </Button>

            </form>

            <img src="/neville-johnson-logo.png" alt="NJ logo" className="max-w-full mt-auto h-auto align-top absolute left-1/2 transform -translate-x-1/2 md:translate-x-0 md:left-10 browser:top-10 app:bottom-10" width="250" height="70" />
            <img src="/tom-howley-logo.png" alt="TH logo" className="max-w-full h-auto align-top hidden md:block absolute right-10 browser:top-10 app:bottom-10" width="250" height="70" />
        </div>
    )
}
export default Scene