import './wdyr'; // <--- first import
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import IndexedDBProvider from 'use-indexeddb'

import './webfonts.css';
import './tailwind.css';


const idbConfig = {
  databaseName: "fim",
  version: 3,
  stores: [
    {
      name: "remedial_categories",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "code", keyPath: "code", options: { unique: true } },
        { name: "description", keyPath: "description" },
      ],
    },
    {
      name: "remedial_subcategories",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "code", keyPath: "code", options: { unique: true } },
        { name: "description", keyPath: "description" },
      ],
    },
    {
      name: "remedial_reasons",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "code", keyPath: "code", options: { unique: true } },
        { name: "reason", keyPath: "reason" },
        { name: "category", keyPath: "category" },
      ],
    },
    {
      name: "remedial_causes",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "code", keyPath: "code", options: { unique: true } },
        { name: "cause", keyPath: "cause" },
        { name: "reason", keyPath: "reason" },
      ],
    },
    {
      name: "remedial_debit",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "code", keyPath: "code", options: { unique: true } },
        { name: "reason", keyPath: "reason" },
        { name: "category", keyPath: "category" },
      ],
    },
    {
      name: "remedial_statuses",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "status", keyPath: "status", options: { unique: true } },
      ],
    },
    {
      name: "fitters",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "name", keyPath: "name", options: { unique: false } },
        { name: "company", keyPath: "company" },
        { name: "category", keyPath: "category" },
      ],
    },
    {
      name: "remedial_processing_priority",
      id: { keyPath: "id", autoIncrement: false },
      indices: [
        { name: "label", keyPath: "label", options: { unique: true } },
      ]
    }
  ],
};

const rootElement = document.getElementById('root');

// 👇️ non-null (!) assertion
const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <IndexedDBProvider config={idbConfig}>
      <App />
    </IndexedDBProvider>
  </StrictMode>
  );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const configuration = {
  onUpdate: (registration:any) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version available! refresh to update your app?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
 };


serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
