import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { sortBy } from 'lodash'
import { SubmissionContext } from '../../../utils/updates'

import { useIndexedDBStore } from 'use-indexeddb'
import { SortDescendingIcon, SortAscendingIcon, FilterIcon, CloudUploadIcon } from '@heroicons/react/solid'
import type { Order, DialogOption, RemedialStatus } from '../../../utils/types'

import Dialog from '../dialog/dialog'

import './table.css'


type TableProps = {
    data: Array<Order>,
}

type FilterSet = {
    status: Array<Object>
}


const Table : React.FC<TableProps> = ({ data, ...props }) => {

    const {
        submission,

    } = React.useContext(SubmissionContext)
    const [ ascending, setAscending ] = useState<boolean>(true)
    const [ sorted, setSorted ] = useState<Array<Order>>([]);
    const [ filtered, setFiltered ] = useState<Array<Order>>([]);
    const [ statuses, setStatuses ] = useState<Array<RemedialStatus>>([]);
    const [ filters, setFilters ] = useState<FilterSet>({
        status: []
    })

    const { getAll:getRemedialStatuses } = useIndexedDBStore('remedial_statuses')

    useEffect(() => {
        getRemedialStatuses().then((statuses:any) => {
            setStatuses(statuses);
        });
    }, [])

    useEffect(() => {

        const sorted = ascending ? sortBy(data, 'CLIENT') : sortBy(data, 'CLIENT').reverse()



        setSorted(sorted);
    }, [ascending, data])

    useEffect(() => {
      //  console.log(filters)
        let clone = [...sorted];
        if(filters.status.length > 0) {
            clone = clone.filter((order:Order) => filters.status.includes(order.INSPECTION_STATUS));
        }

        setFiltered(clone);
    }, [ filters, sorted ]);

    const submissionKeys = Object.keys(submission);

    const customer: any = {
        '0': 'NJF',
        '1': 'NJS',
        '2': 'TH',
        '4': 'LDC'
    }

    return (
        <div className=" max-w-full ">

            <div className="table__tools">
                <Dialog
                    label={'Filter reports'}
                    reset={() => setFilters({...filters, status: []})}
                    options={statuses.map((status:any) => ({
                        value: status.id,
                        label: status.status
                    }))}
                    active={filters?.status}
                    select={(option:DialogOption) => {
                        if(filters.status.includes(option.value)) {
                            setFilters({...filters, status: filters.status.filter((status) => status !== option.value)})
                        }
                        else {
                            setFilters({...filters, status: [...filters?.status, option.value ] })
                        }
                    }}
                >
                    <span>Filter</span>
                    <FilterIcon className="w-4 h-4 align-top" />
                </Dialog>
            </div>
        <div className="max-w-full">
        <table className="dashboard__table" cellSpacing={0}>
            <thead>
                <tr>
                    <th>Client
                            <button type="button" onClick={() => setAscending(!ascending)}>
                                { ascending && <SortAscendingIcon className="text-red inline-block w-4 h-4 ml-1" /> }
                                { !ascending && <SortDescendingIcon className="text-red inline-block w-4 h-4 ml-1" /> }
                            </button></th>
                    <th>Order no.</th>
                    <th>Account</th>
                    <th>Updated</th>
                    <th>Postcode</th>
                    <th>Inspection Request</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
            {filtered && filtered.map(row => {
                //submission[order.ORDER_REF]?.INTERNAL_CATEGORY
                let inspection_status = null;
                if(submission[row.ORDER_REF]?.INSPECTION_STATUS) {
                    inspection_status = statuses.find((status:RemedialStatus) => status.id.toString() === submission[row.ORDER_REF]?.INSPECTION_STATUS);
                }
                else {
                    inspection_status = statuses.find((status:RemedialStatus) => status.id.toString() === row.INSPECTION_STATUS);
                }
                return (
                    <tr key={row.ORDER_REF}>
                        <th>
                            <Link to={`/${process.env.REACT_APP_VERSION}/order/${row.ORDER_REF}`}>{[row.TITLE, row.CLIENT].join(' ')}</Link>
                        </th>
                        <td>{row.ORDER_REF} ({customer[row.BUS_ID.toString()]})</td>
                        <td>{row.ACCOUNT_NO}</td>
                        <td>{row.SYNCH_DATE_TIME && format(new Date(row.SYNCH_DATE_TIME), 'dd/MM/Y')}</td>
                        <td>{row.POSTCODE}</td>
                        <td>{inspection_status?.status || ''}</td>
                        <td width={20}>
                            {submissionKeys.includes(row.ORDER_REF) && <CloudUploadIcon className="w-5 h-5 text-red" />}
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
        </div>
        </div>
    )
}

export default Table