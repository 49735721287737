import React, { useState } from 'react'
//import Select from 'react-select'
import Menu from '../../menu/menu'

import type { Order, RemedialCause, RemedialReason } from '../../../../utils/types'

import '../combo-group.css'

type MattersArisingProps = {
    reasons: Array<RemedialReason>,
    causes: Array<RemedialCause>,
    register: any,
    order: Order,
    actions: any
}


const MattersArising: React.FC<MattersArisingProps> = ({ reasons, causes, register, order, actions }) => {
    const [tab, setTab] = useState(0);

    return (
        <div className="combo-group">
            <aside>
                <button disabled={tab === 0} onClick={() => setTab(0)} type="button">Reason and action 1</button>
                <button disabled={tab === 1} onClick={() => setTab(1)} type="button">Reason and action 2</button>
                <button disabled={tab === 2} onClick={() => setTab(2)} type="button">Reason and action 3</button>
                <button disabled={tab === 3} onClick={() => setTab(3)} type="button">Reason and action 4</button>
                <button disabled={tab === 4} onClick={() => setTab(4)} type="button">Reason and action 5</button>
            </aside>
            <div className="p-3 w-full">
                {
                     [
                        {
                            code: 'MATTERS_1_REASON',
                            note: 'MATTERS_1_NOTE',
                            cause: 'MATTERS_1_CAUSE',
                            attempt: 'MATTERS_1_ATTEMPT'
                        },
                        {
                            code: 'MATTERS_2_REASON',
                            note: 'MATTERS_2_NOTE',
                            cause: 'MATTERS_2_CAUSE',
                            attempt: 'MATTERS_2_ATTEMPT'
                        }, {
                            code: 'MATTERS_3_REASON',
                            note: 'MATTERS_3_NOTE',
                            cause: 'MATTERS_3_CAUSE',
                            attempt: 'MATTERS_3_ATTEMPT'
                        }, {
                            code: 'MATTERS_4_REASON',
                            note: 'MATTERS_4_NOTE',
                            cause: 'MATTERS_4_CAUSE',
                            attempt: 'MATTERS_4_ATTEMPT'
                        }, {
                            code: 'MATTERS_5_REASON',
                            note: 'MATTERS_5_NOTE',
                            cause: 'MATTERS_5_CAUSE',
                            attempt: 'MATTERS_5_ATTEMPT'
                        }
                    ].map((row:any, i:number) => {

                        let defaultCode: any = null;
                        //let defaultCodeLabel = null;
                        let defaultNote = null;
                        let defaultCause = null;
                        let defaultAttempts:any = null;

                        if(order[row.code as keyof Order]) {
                            defaultCode = order[row.code as keyof Order]
                        }
                        if(order[row.note as keyof Order]) {
                            defaultNote = order[row.note as keyof Order]
                        }
                        if(order[row.cause as keyof Order]) {
                            defaultCause = order[row.cause as keyof Order]
                        }
                        if(order[row.cause as keyof Order]) {
                            defaultAttempts = order[row.attempt as keyof Order]
                        }
                        if(actions.submission[order.ORDER_REF] && (actions.submission[order.ORDER_REF][row.code as keyof Order] || actions.submission[order.ORDER_REF][row.code as keyof Order] === '')) {
                            defaultCode = actions.submission[order.ORDER_REF][row.code as keyof Order]
                        }
                        if(actions.submission[order.ORDER_REF] && (actions.submission[order.ORDER_REF][row.note as keyof Order] || actions.submission[order.ORDER_REF][row.note as keyof Order] === '' )) {
                            defaultNote = actions.submission[order.ORDER_REF][row.note as keyof Order]
                        }
                        if(actions.submission[order.ORDER_REF] && (actions.submission[order.ORDER_REF][row.cause as keyof Order] || actions.submission[order.ORDER_REF][row.cause as keyof Order] === '')) {
                            defaultCause = actions.submission[order.ORDER_REF][row.cause as keyof Order]
                        }
                        if(actions.submission[order.ORDER_REF] && (actions.submission[order.ORDER_REF][row.attempt as keyof Order] || actions.submission[order.ORDER_REF][row.attempt as keyof Order] === '')) {
                            defaultAttempts = actions.submission[order.ORDER_REF][row.attempt as keyof Order]
                        }

//                        defaultCode = '160';

                        const filteredCauses = causes.filter(({ reason }) => reason.toString() === defaultCode);

                        defaultAttempts = defaultAttempts ?? null;
                        return (
                            <div className="combo-group__matter" key={i} aria-visible={tab === i}>
                                {/*<Select
                                    value={defaultCode}
                                    placeholder={defaultCodeLabel || '- Select reason -'}
                                    options={reasons.map((reason) => ({
                                        value: reason.id.toString(),
                                        label: `${reason.code} - ${reason.reason}`
                                    }))}
                                    isClearable={true}
                                    {...register(row.code)}
                                    className="order-2"
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.code]: value}
                                        })
                                    }}
                                />
                                */}

                                {/*<select {...register(row.code)}>
                                    {reasons.map((reason) => (
                                        <option key={reason.code} value={reason.id.toString()}>{`${reason.code} - ${reason.reason}`}</option>
                                    ))}
                                </select>*/}
                                <Menu
                                    hot={defaultCode}
                                    fallback={defaultCode}
                                    options={reasons.map((reason) => ({
                                        value: reason.id.toString(),
                                        label: `${reason.code} - ${reason.reason}`
                                    }))}
                                    onClear={() => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.code]: ''}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.code]: value}
                                        })
                                    }}
                                />

                                <div className="flex items-center justify-between gap-x-5 mt-2">


                                {filteredCauses.length > 0 ? (
                                    <div className="flex-1">
                                        <Menu
                                            hot={defaultCause}
                                            fallback={defaultCause}
                                            options={filteredCauses.map((cause) => ({
                                                value: cause.id.toString(),
                                                label: `${cause.code} - ${cause.cause}`
                                            }))}
                                            onClear={() => {
                                                actions.setSubmission({
                                                    ...actions.submission,
                                                    [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.cause]: ''}
                                                })
                                            }}
                                            onSelect={(value:string) => {
                                                actions.setSubmission({
                                                    ...actions.submission,
                                                    [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.cause]: value}
                                                })
                                            }}
                                        />
                                    </div>
                                ) : null}

                                    <div className="flex-0 flex items-center gap-x-2">
                                        <p>Attempts</p>
                                        <Menu
                                            hot={defaultAttempts?.toString()}
                                            defaultLabel={'- Select -'}
                                            fallback={defaultAttempts?.toString()}
                                            options={['1','2','3','4','5'].map((a) => ({
                                                value: a,
                                                label: a
                                            }))}
                                            onClear={() => {
                                                actions.setSubmission({
                                                    ...actions.submission,
                                                    [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.attempt]: null}
                                                })
                                            }}
                                            onSelect={(value:string) => {
                                                actions.setSubmission({
                                                    ...actions.submission,
                                                    [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.attempt]: parseInt(value)}
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                                <textarea
                                    rows={3}
                                    name={row.note}
                                    //disabled={!defaultCode}
                                    placeholder={'Type note (1500 characters maximum)'}
                                    maxLength={1500}
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }

                                        //debounced(name, value);
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [name]: value}
                                        })
                                    }}
                                    value={defaultNote || ''}
                                />

                                <button
                                    className='text-red'
                                    type="button"
                                    onClick={() => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF],
                                                [row.note]: '',
                                                [row.code]: '',
                                                [row.cause]: '',
                                                [row.attempt]: 1
                                            }
                                        })
                                    }}
                                >Clear Matter</button>

                                </div>


                        )
                    })
                }
            </div>
        </div>
    )
}
export default MattersArising

