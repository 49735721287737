import React, { useState, useRef, useEffect, ReactNode } from 'react'

//import ClickOutHandler from 'react-onclickout';
import { DialogOption } from '../../../utils/types'
import { useKeypress } from '../../../utils/custom-hooks'

import './dialog.css'

type DialogProps = {
    children: ReactNode,
    label: String,
    options: Array<DialogOption>,
    reset: Function,
    select: Function,
    active?: Array<Object>
}

const Dialog : React.FC<DialogProps> = ({ children, label, options, reset, active, select, ...props }) => {
    const [ open, setOpen ] = useState<boolean>(false);

    useKeypress('Escape', () => {
        setOpen(false)
    });


    useEffect(() => {
        function handler(event:any) {
            if(!popup.current?.contains(event.target) && !trigger.current?.contains(event.target)) {
                setOpen(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, []);

    const popup = useRef<HTMLInputElement>(null);
    const trigger = useRef<HTMLButtonElement>(null);

    return (
        <div className="dialog" {...props}>
            <button ref={trigger} className="dialog__toggle" onClick={() => setOpen(!open)} type="button">{children}</button>

            { open && (
                <div className="dialog__popup" ref={popup}>
                {label && (
                    <header>
                        {reset && <button type="button" onClick={async () => {
                            await reset()
                            setOpen(false)
                        }}>Reset</button>}
                        <strong className="mx-auto absolute left-1/2 transform -translate-x-1/2">{label}</strong>
                    </header>
                )}
                {options && (
                            <ul>
                                {options.map((option, i) => (
                                    <li key={i}>
                                        {/*<button
                                            type="button"
                                            className={active?.includes(option.value) ? 'is-checked bg-red text-white' : ''}
                                            onClick={() => select(option)}>
                                            {option.label}
                                </button>*/}
                                        <label htmlFor={`option__${i}`}>
                                            <input
                                                type="checkbox"
                                                name={'filters'}
                                                value={option.value.toString()}
                                                checked={active?.includes(option.value.toString())}
                                                onChange={(e) => {
                                                    //console.log(e.target.value)
                                                    select({
                                                        label: e.target.name,
                                                        value: e.target.value
                                                    })
                                                    //select(target.value)
                                                }}
                                                id={`option__${i}`}
                                            />
                                            <span>{option.label}</span>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        )}

                </div>
            )}

            {/*open && (
                <ClickOutHandler onClickOut={() => setOpen(false)}>
                    <div className="dialog__popup">
                        {label && (
                            <header>
                                {reset && <button type="button" onClick={async () => {
                                    await reset()
                                    setOpen(false)
                                }}>Reset</button>}
                                <strong className="mx-auto absolute left-1/2 transform -translate-x-1/2">{label}</strong>
                            </header>
                        )}
                        {options && (
                            <ul>
                                {options.map((option, i) => (
                                    <li key={i}>
                                        <button
                                            type="button"
                                            className={active?.includes(option.value) ? 'is-checked bg-red text-white' : ''}
                                            onClick={() => select(option)}>
                                            {option.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                </div>
                </ClickOutHandler>
                                )*/}
        </div>
    )
}

export default Dialog