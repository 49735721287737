import React from 'react'
import { useLocalStorage } from '../utils/custom-hooks'

export const SubmissionContext = React.createContext();


const SubmissionProvider = props => {
    const [submission, setSubmission] = useLocalStorage(`updates__${process.env.REACT_APP_VERSION}`, {});
    


    return (
        <SubmissionContext.Provider
            value={{
                submission,
                setSubmission
            }}
        >
            {props.children}
        </SubmissionContext.Provider>
    )
}


export default SubmissionProvider;

