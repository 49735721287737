import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { useLocalStorage } from '../../utils/custom-hooks';
import { useQuery } from 'react-query'
import { useIndexedDBStore } from 'use-indexeddb'

import type { Order } from '../../utils/types'
import Header from '../components/order/header'

import App from '../components/order/container'


import UpdateProvider from '../../utils/updates'
//import { Settings } from '../../utils/types'

const Scene = ({ ...props }) => {
    const { id } = useParams();
    let navigate = useNavigate();


    const { getAll:getRemedialStatuses } = useIndexedDBStore('remedial_statuses')
    const { getAll:getRemedialCategories } = useIndexedDBStore('remedial_categories')
    const { getAll:getRemedialSubcategories } = useIndexedDBStore('remedial_subcategories')
    const { getAll:getRemedialReasons } = useIndexedDBStore('remedial_reasons')
    const { getAll:getRemedialCauses } = useIndexedDBStore('remedial_causes')
    const { getAll:getFitters } = useIndexedDBStore('fitters')
    const { getAll:getRemedialDeit } = useIndexedDBStore('remedial_debit')
    const { getAll:getRemedialProcessingPriority } = useIndexedDBStore('remedial_processing_priority')


    const { data: settings, isLoading, isSuccess } = useQuery('settings', async () => {
        return {
            remedialStatuses: await getRemedialStatuses(),
            remedialCategories: await getRemedialCategories(),
            remedialSubcategories: await getRemedialSubcategories(),
            remedialReasons: await getRemedialReasons(),
            remedialCauses: await getRemedialCauses(),
            fitters: await getFitters(),
            remedialDebit: await getRemedialDeit(),
            remedial_processing_priority: await getRemedialProcessingPriority()
        }
    });

    const [ online, setOnline ] = useState(false);
    const [ orders,  ] = useLocalStorage(`orders__${process.env.REACT_APP_VERSION}`, []);
    const [ token,  ] = useLocalStorage(`user__${process.env.REACT_APP_VERSION}`, null);

    const order = orders.find((order: Order) => order.ORDER_REF === id);


    useEffect(() => {
        if(!token) {
            navigate(`/${process.env.REACT_APP_VERSION}/login`)
        }
    }, [token, navigate]);

    useEffect(() => {
        setOnline(window.navigator.onLine);
        window.addEventListener('offline', function(e) { setOnline(false); });
        window.addEventListener('online', function(e) { setOnline(true); });
    }, [window.navigator.onLine, navigate]);

    return (
        <div className="bg-silver dark:bg-zinc-700 text-black dark:text-white ">

            <Helmet title={`${order.ORDER_REF} | ${order.CLIENT}`} defer={false}>
            </Helmet>
            <Header order={order} />

            {isLoading ? <span>Loading...</span> : (
            <UpdateProvider>
            {isSuccess && <App
                order={order}
                token={token}
                online={online}
                settings={settings}
                />}
            </UpdateProvider>)}
        </div>
    )
}
export default Scene