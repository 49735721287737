import React, {useState, useCallback, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'

import './photos.css'
import 'react-dropzone-uploader/dist/styles.css'

type PhotosProps = {
    register: any,
    unregister: any
}

const Photos: React.FC<PhotosProps> = ({ register, unregister }) => {
    const [ photos, setPhotos ] = useState<Array<string>>([])

    const onDrop = useCallback(async (acceptedFiles:any) => {
        //let temp: Array<any> = [];

        let temp: any = await Promise.all(acceptedFiles.map((file:File) => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onabort = () => reject('file reading was aborted')
            reader.onerror = () => reject('file reading has failed')
            reader.onload = async () => {
                const encoded = await rotate(reader.result as string, 1, 1720);
                resolve(encoded);
            }
            reader.readAsDataURL(file)
        })))

        setPhotos([...photos, ...temp]);

    }, [photos])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const displayUploader = useMemo(() => (
<div className="photos">
        <div {...getRootProps()}>
          <input
          {...getInputProps()}
          //capture="environment"
          accept="image/jpeg" />
      <p>Choose images</p>
        </div>
        {photos.length > 0 && (
        <ul className="photos__set">
            {photos.map((photo, i) => (
                <li key={i}>
                    <img src={photo} alt={`Image ${i}`} />
                    <input type='hidden' {...register(`IMAGE${i+1}`, { value: photo })} />
                    <button className="photos__remove" type="button" onClick={() => {
                        setPhotos(photos.filter(photoEncode => photoEncode !== photo))
                        unregister(`IMAGE${i+1}`)
                    }}>Remove</button>
                </li>
            ))}
        </ul>
        )}
        </div>
    ), [photos, register]);

    return (
        <>
            {displayUploader}

        {/*<button type="button" className="text-red  col-span-12 text-left" onClick={() => {
            //window.location.reload()

        }}>
            <strong className="underline">Reload image uploader</strong>
            <small className="block text-black">Use this if the above button isn't showing the file menu</small>
    </button>*/}
        </>
    )

}
export default Photos;

/*
function getBase64(file:any) {
    return new Promise(async (resolve, reject) => {

        const x = await applyRotation(file, 1, 450);
        resolve(x);

    });
 }*/

 const rotate = async (file: string, orientation: number, maxWidth: number) => new Promise<string>(resolve => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;

    const image = new Image();
    image.src = file;

    image.onload = () => {
        let { width, height } = image;

        const [outputWidth, outputHeight] = orientation >= 5 && orientation <= 8
                ? [height, width]
                : [width, height];

            const scale = outputWidth > maxWidth ? maxWidth / outputWidth : 1;

            width = width * scale;
            height = height * scale;

            // set proper canvas dimensions before transform & export
            canvas.width = outputWidth * scale;
            canvas.height = outputHeight * scale;

            // transform context before drawing image
            switch (orientation) {
                case 2: context.transform(-1, 0, 0, 1, width, 0); break;
                case 3: context.transform(-1, 0, 0, -1, width, height); break;
                case 4: context.transform(1, 0, 0, -1, 0, height); break;
                case 5: context.transform(0, 1, 1, 0, 0, 0); break;
                case 6: context.transform(0, 1, -1, 0, height, 0); break;
                case 7: context.transform(0, -1, -1, 0, height, width); break;
                case 8: context.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }

            // draw image
            context.drawImage(image, 0, 0, width, height);

            resolve(canvas.toDataURL("image/jpeg"));
    }

 })

 /**
 * @returns Base64 Image URL (with rotation applied to compensate for orientation, if any)

  const applyRotation = async (file: File, orientation: number, maxWidth: number) => new Promise<string>(resolve => {
    const reader = new FileReader();

    reader.onload = () => {
        const url = reader.result as string;

        const image = new Image();

        image.onload = () => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d")!;

            let { width, height } = image;

            const [outputWidth, outputHeight] = orientation >= 5 && orientation <= 8
                ? [height, width]
                : [width, height];

            const scale = outputWidth > maxWidth ? maxWidth / outputWidth : 1;

            width = width * scale;
            height = height * scale;

            // set proper canvas dimensions before transform & export
            canvas.width = outputWidth * scale;
            canvas.height = outputHeight * scale;

            // transform context before drawing image
            switch (orientation) {
                case 2: context.transform(-1, 0, 0, 1, width, 0); break;
                case 3: context.transform(-1, 0, 0, -1, width, height); break;
                case 4: context.transform(1, 0, 0, -1, 0, height); break;
                case 5: context.transform(0, 1, 1, 0, 0, 0); break;
                case 6: context.transform(0, 1, -1, 0, height, 0); break;
                case 7: context.transform(0, -1, -1, 0, height, width); break;
                case 8: context.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }

            // draw image
            context.drawImage(image, 0, 0, width, height);

            // export base64
            resolve(canvas.toDataURL("image/jpeg"));
        };

        image.src = url;
    }

    reader.readAsDataURL(file);
}); */