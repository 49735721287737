import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce';
//import OffClick from 'react-offclick';

import { XIcon } from '@heroicons/react/outline'

type TableProps = {
    field: string,
    label: string,
    defaultValue: string,
    previousValue: string,
    callback: Function
}



const Comments : React.FC<TableProps> = ({ field, label, defaultValue, previousValue, callback, ...props }) => {
    const [ open, setOpen ] = useState<Boolean>(false);
    //const [text, setText] = useState<string>('');
    //const [value] = useDebounce(text, 600);

    const debounced = useDebouncedCallback(
        // function
        (value) => {
            callback({ field, value });
        },
        // delay in ms
        700
      );

    return (
        <div {...props}>
            <textarea 
                id={field} 
                defaultValue={defaultValue}
                onChange={(e) => {
                    debounced(e.target.value);
                }}
            />
            <label htmlFor={field}>{label}</label>
            <button onClick={() => setOpen(!open)} type="button">View previous comments</button>
            <dialog open={open === true}>
                <button type="button" className="w-6 h-6 text-red" onClick={() => setOpen(false)}>
                    <XIcon className="w-full h-auto align-top" />
                </button>
                <textarea id="ORDER_COMMENT_DEFAULT" readOnly defaultValue={previousValue} />
            </dialog>
        </div>
    )
}

export default Comments