import React, { useState, useEffect, MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import { useIndexedDBStore } from 'use-indexeddb'

import Table from '../components/table'
import Search from '../components/search/search'

import { SearchIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/outline'
import type { Order } from '../../utils/types'

import { useLocalStorage } from '../../utils/custom-hooks'
import UpdateProvider, { SubmissionContext } from '../../utils/updates'


/*type SearchKeywords = {
    name: String,
    value: String
}*/

type SearchSet = {
    client: string,
    postcode: string
}


const Scene = () => {
    const [ online, setOnline ] = useState(false);
    const [ status, setStatus ] = useState<string>('')
    const [ tool, setTool ] = useState<string>('')
    const [ filtered, setFiltered ] = useState<Array<Order>>([]);

    let navigate = useNavigate();
    const [ orders, setOrders ] = useLocalStorage(`orders__${process.env.REACT_APP_VERSION}`, []);
    const [ updates ] = useLocalStorage(`updates__${process.env.REACT_APP_VERSION}`, []);
    const [ token, setToken ] = useLocalStorage(`user__${process.env.REACT_APP_VERSION}`, null);
    const [ search, setSearch ] = useState<SearchSet>({
        client: '',
        postcode: ''
    })


    const { deleteAll:deleteAllFitters } = useIndexedDBStore("fitters");
    const { deleteAll:deleteAllRemedialCategories } = useIndexedDBStore("remedial_categories");
    const { deleteAll:deleteAllRemedialSubCategories } = useIndexedDBStore("remedial_subcategories");
    const { deleteAll:deleteAllRemedialStatuses } = useIndexedDBStore("remedial_statuses");
    const { deleteAll:deleteAllRemedialDebit } = useIndexedDBStore("remedial_debit");
    const { deleteAll:deleteAllRemedialReasons } = useIndexedDBStore("remedial_reasons");
    const { deleteAll:deleteAllRemedialCauses } = useIndexedDBStore("remedial_causes");
    const { deleteAll:deleteAllRemedialProcessingPriorities } = useIndexedDBStore("remedial_processing_priority");


    //'Loading reports...'      // fetch jobs

    useEffect(() => {
        setOnline(window.navigator.onLine);
        window.addEventListener('offline', function(e) { setOnline(false); });
        window.addEventListener('online', function(e) { setOnline(true); });
    }, []);

    useEffect(() => {
        if(token) {
            setStatus(token.name)
        }
        else {
            navigate(`/${process.env.REACT_APP_VERSION}/login`)
        }
    }, [token]);

    useEffect(() => {
        let clone = [...orders];

        if(search.client.length > 3) {
            clone = clone.filter((order:Order) => order.CLIENT.toLowerCase().includes(search.client.toLowerCase()));
        }
        if(search.postcode.length > 1) {
            clone = clone.filter((order:Order) => order.POSTCODE.toLowerCase().includes(search.postcode.toLowerCase()));
        }

        setFiltered(clone);
    }, [ orders, search ]);


    const sync = async (payload:any) => {

        setStatus('syncing');
        const { data } = await fetch(process.env.REACT_APP_AZURE_FUNCTIONS + 'sync', {
            method: 'PATCH',
            headers: new Headers({
                'content-type': 'application/json',
                'flag': (process.env.REACT_APP_VERSION || '')
            }),
            body: JSON.stringify({
                user: token,
                //fimid: token.ref,
                //fimpassword: token.password_hash,
                updates
            })
        }).then(async res => {
            if(res.ok) {
                return await res.json();
            }
            throw res.status;
        })
        .catch(error => {
            console.log({
                error
            });
        })


        // refetch orders
        const refetched = await fetch(process.env.REACT_APP_AZURE_FUNCTIONS + `orders?uid=${token.ref}&token=${token.password_hash}`, {
            method: 'GET',
            headers: new Headers({
                'content-type': 'application/json'
            }),
        }).then(async res => {
            if(res.ok) {
                return await res.json();
            }
            throw res.status;
        })


        setOrders(refetched.orders);
        //setOrders(data.reports);
        setStatus('synced');

        window.localStorage.removeItem(`updates__${process.env.REACT_APP_VERSION}`)

    }

    const logout = async () => {
        if(window.confirm('Are you sure you want to log out?')) {
            window.localStorage.removeItem('orders')
            window.localStorage.removeItem('updates')
            setToken(null)

            await deleteAllFitters
            await deleteAllRemedialCategories
            await deleteAllRemedialSubCategories
            await deleteAllRemedialStatuses
            await deleteAllRemedialDebit
            await deleteAllRemedialReasons
            await deleteAllRemedialCauses
            await deleteAllRemedialProcessingPriorities
       }
    }

    const checkForUpdates = () => {
        window.location.reload();
    }


    return (
        <div className="bg-silver dark:bg-zinc-800 min-h-screen pb-16">

            <Helmet title="Dashboard" defer={false}>
            </Helmet>

            <UpdateProvider>
            <header className="h-28 top-0 w-full px-6 md:px-10 bg-white dark:bg-zinc-800 flex items-center justify-between relative gap-x-2 md:gap-x-8 pb-10">
                    <button type="button" className="text-red h-10 text-base antialised inline-flex items-center " onClick={logout}>Log out</button>
                    <button
                        type="button"
                        disabled={orders.length === 0}
                        className="text-red h-10 text-base antialised inline-flex gap-x-1 items-center order-3 ml-auto relative"
                        onClick={() => tool === 'search' ? setTool('') : setTool('search')}
                        >
                        <SearchIcon className={`${tool === 'search' ? 'absolute opacity-0' : 'opacity-100'} transition-opacity w-8 h-8 block`} />
                        <XIcon className={`${tool !== 'search' ? 'absolute opacity-0' : 'opacity-100'} transition-opacity w-8 h-8 block`} />
                        <span className="sr-only">Search</span>
                    </button>
                    <SyncButton action={sync} online={online} status={status} />

                    <h1 className={[(tool === 'search' ? 'opacity-0' : 'opacity-100'),`order-2 text-center leading-none absolute left-1/2 transform -translate-x-1/2 transition-opacity`].join(' ')}>
                        <strong className="text-xl block font-normal text-black dark:text-white">{token?.name}</strong>
                        <small className="text-sm block font-normal text-black dark:text-white mt-1">{format(new Date(), 'dd/MM/Y')}</small>
                        <small className="text-xs block font-normal text-neutral-400 dark:text-white mt-1">Version {process.env.REACT_APP_VERSION}&nbsp;<button onClick={checkForUpdates} className="text-red" type="button">Check for app updates</button></small>
                    </h1>

                    <Search
                        display={tool === 'search'}
                        callback={(keywords:any) => {
                            setSearch({...search, [keywords.name]: keywords.value });
                        }}
                    />

                </header>

                <Table
                    data={filtered}
                />

</UpdateProvider>


        </div>
    )
}
export default Scene

type SyncButtonConfig = {
    action: MouseEventHandler,
    online: boolean,
    status: string
}

const SyncButton: React.FC<SyncButtonConfig> = ({ action, online, status }) => {

    const {
        submission,
        setSubmission
    } = React.useContext(SubmissionContext)

    const updates = Object.keys(submission).length

    const msg = updates === 0 ? 'Fetching...' : 'Syncing your changes...'
    /*if(updates === 0) {
        return <span />
    }*/

    return (
        <button
            type="button"
            disabled={!online || status === 'syncing'}
            onClick={async () => {
                await action(submission)
                setSubmission({})
            }}
            className={`${status === 'syncing' ? 'animate-pulse' : ''} relative text-red h-10 text-base antialised inline-flex items-center order-4 disabled:text-neutral-400`}>
            <span>{status === 'syncing' ? msg : 'Sync'}</span>
        </button>
    )
}