import React, { useState } from 'react'
import Menu from '../../menu/menu'

import type { Order, RemedialReason } from '../../../../utils/types'

import '../combo-group.css'

type ReasonsProps = {
    reasons: Array<RemedialReason>,
    register: any,
    order: any,
    actions: any
}


const Reasons: React.FC<ReasonsProps> = ({ reasons, register, order, actions }) => {
    const [tab, setTab] = useState(0);

    return (
        <div className="combo-group">
            <aside className="last" >
                <button disabled={tab === 0} onClick={() => setTab(0)} type="button">Debit 1</button>
                <button disabled={tab === 1} onClick={() => setTab(1)} type="button">Debit 2</button>
                <button disabled={tab === 2}onClick={() => setTab(2)} type="button">Debit 3</button>
            </aside>
            <div className="p-3 w-full">
                {
                     [
                        {
                            code: 'DEBIT_CODE_1',
                            note: 'DEBIT_NOTE_1'
                        },
                        {
                            code: 'DEBIT_CODE_2',
                            note: 'DEBIT_NOTE_2',
                        }, {
                            code: 'DEBIT_CODE_3',
                            note: 'DEBIT_NOTE_3'
                        }
                    ].map((row:any, i:number) => {

                        let defaultCode: any = null;
                            //let defaultCodeLabel = null;
                            let defaultNote = null;

                            if(order[row.code as keyof Order]) {
                                defaultCode = order[row.code as keyof Order]
                            }
                            if(order[row.note as keyof Order]) {
                                defaultNote = order[row.note as keyof Order]
                            }
                            if(actions.submission[order.ORDER_REF] && (actions.submission[order.ORDER_REF][row.code as keyof Order] || actions.submission[order.ORDER_REF][row.code as keyof Order] === '')) {
                                defaultCode = actions.submission[order.ORDER_REF][row.code as keyof Order]
                                //defaultCodeLabel = '- Select reason -'
                            }
                            if(actions.submission[order.ORDER_REF] && (actions.submission[order.ORDER_REF][row.note as keyof Order] || actions.submission[order.ORDER_REF][row.note as keyof Order] === '')) {
                                defaultNote = actions.submission[order.ORDER_REF][row.note as keyof Order]
                            }

                            /*if(defaultCode) {
                                let t = reasons.find(setting => {
                                    return setting.id.toString() === defaultCode
                                })
                                //defaultCodeLabel = t ? `${t.code} - ${t.reason}` : defaultCodeLabel
                            }*/


                        return (
                            <div  className="combo-group__matter" key={i} aria-visible={tab === i}>
                                {/*<Select
                                    value={defaultCode}
                                    placeholder={defaultCodeLabel || '- Select debit code -'}
                                    options={reasons.map((reason) => ({
                                        value: reason.id.toString(),
                                        label: `${reason.code} - ${reason.reason}`
                                    }))}
                                    isClearable={true}
                                    {...register(row.code)}
                                    className="order-2"
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.code]: value}
                                        })
                                    }}
                                />*/}


                                <Menu
                                    hot={defaultCode}
                                    fallback={defaultCode}
                                    options={reasons.map((reason) => ({
                                        value: reason.id.toString(),
                                        label: `${reason.code} - ${reason.reason}`
                                    }))}
                                    onClear={() => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.code]: ''}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.code]: value}
                                        })
                                    }}
                                />

                                <textarea
                                    rows={3}
                                    name={row.note}
                                    //disabled={!defaultCode}
                                    placeholder={'Type note (1500 characters maximum)'}
                                    maxLength={1500}
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }

                                        //debounced(name, value);
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [name]: value}
                                        })
                                    }}
                                    value={defaultNote}
                                />
                                <button
                                    className='text-red mt-4'
                                    type="button"
                                    onClick={() => {
                                        actions.setSubmission({
                                            ...actions.submission,
                                            [order.ORDER_REF]: {...actions.submission[order.ORDER_REF], [row.note]: '', [row.code]: ''}
                                        })
                                    }}
                                >Clear debit</button>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default Reasons