import React, { useState } from 'react'
//import { useParams, NavLink, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SubmissionContext } from '../../../utils/updates'
import { useDebouncedCallback } from 'use-debounce';
import { CalendarIcon,  } from '@heroicons/react/outline'
//import Select from 'react-select';

import Button from '../button/large'
//import { PhotographIcon } from '@heroicons/react/solid'
//import { XIcon, PlusIcon } from '@heroicons/react/outline'
//import Spinner from '../spinner/circle'
import MattersArising from './report/matters-arising';
import Photos from './report/photos';
import Menu from '../menu/menu'

import './container.css'
import './form.css'
import './img.css'

import type { Order, RemedialCategory, RemedialReason, User, RemedialProcessingPriority, RemedialCause } from '../../../utils/types'

type SceneProps = {
    order: Order,
    active: Boolean,
    token: User,
    online: boolean,
    remedialCategories: Array<RemedialCategory>,
    remedialSubcategories: Array<RemedialCategory>,
    remedialReasons: Array<RemedialReason>,
    remedialCauses: Array<RemedialCause>,
    remedialProcessingPriority: Array<RemedialProcessingPriority>
}

const Report: React.FC<SceneProps> = ({ order, active, token, online, remedialCategories, remedialSubcategories, remedialReasons, remedialCauses, remedialProcessingPriority, ...props }) => {
    const {
        register,
        handleSubmit,
    //    watch,
    //    getValues,
        unregister
    } = useForm({
        defaultValues: {
            //MATTERS_1_REASON: '89'
        }
    });
    const [ status, setStatus ] = useState<String>('')


    const {
        submission,
        setSubmission
    } = React.useContext(SubmissionContext)


    const categories = remedialCategories;
    const subcategories = remedialSubcategories;
    const bus_first_letter = order.BusLabel === 'Tom Howley' ? 'K' : order.BusLabel[0].toUpperCase();
    const reasons = remedialReasons.filter((reason:RemedialReason) => reason.code[0] === bus_first_letter)
    const causes = remedialCauses;

    const onSubmit = async (values:any) => {

        setStatus('sending-report')

        let report = {
            ...order,
            ...values,
            ...submission[order.ORDER_REF]
        }

        const send = await fetch(process.env.REACT_APP_AZURE_FUNCTIONS + 'report', {
            method: 'POST',
            headers: new Headers({
                'content-type': 'application/json',
                'flag': (process.env.REACT_APP_VERSION || '')
            }),
            body: JSON.stringify({
                fimid: token.ref,
                fimpassword: token.password_hash,
                report,
                images: [values.IMAGE1, values.IMAGE2, values.IMAGE3, values.IMAGE4, values.IMAGE5].filter(img => img),
                updates: submission[order.ORDER_REF],
            })
        }).then(async res => {
            if(res.ok) {
                return await res.json();
            }
            throw res.status;
        })
        .then(() => setStatus('sent'))
        .catch(error => {
            console.log(error)
            setStatus('error')
        })
    }


    const debounced = useDebouncedCallback(
        // function
        (name, value) => {
            //callback({ field, value });
            //const clone = {...submission}
            //const ref = submission[order.ORDER_REF];

            setSubmission({
                ...submission,
                [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
            })
        },
        // delay in ms
        300
      );

      console.log(order);

    return (

        <div className={`report scene ${active ? 'is-active' : ''}`}>
            <form className="scene__form" onSubmit={handleSubmit(onSubmit)}>
                <h2 className="row-start-1 md:col-span-2 md:col-start-1 mb-6">Failed Fit - Installation Managers Report</h2>


                <fieldset className="md:col-span-1 md:row-start-2 md:col-start-1">
                    <div className="form-field form-field--select col-span-12" style={{zIndex:2}}>
                        {categories && (
                        <div className="select">
                            <Menu
                                hot={submission[order.ORDER_REF]?.INTERNAL_CATEGORY}
                                fallback={order?.INTERNAL_CATEGORY}
                                options={categories.map(status => ({
                                    value: status.id.toString(),
                                    label: status.description
                                }))}
                                onClear={() => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INTERNAL_CATEGORY': ''}
                                    })
                                }}
                                onSelect={(value:string) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INTERNAL_CATEGORY': value}
                                    })
                                }}
                            />
                            {/*<Select
                                defaultValue={submission[order.ORDER_REF]?.INTERNAL_CATEGORY || order?.INTERNAL_CATEGORY}
                                //placeholder={submission[order.ORDER_REF]?.INTERNAL_CATEGORY || order?.INTERNAL_CATEGORY || "- Select -"}
                                placeholder={categories.find(status => {
                                    let selected = submission[order.ORDER_REF]?.INTERNAL_CATEGORY || order?.INTERNAL_CATEGORY
                                    return status.id.toString() === selected
                                })?.description || '- Select -'}
                                options={[{
                                    value: '0',
                                    label: 'None'
                                }, ...categories.map(status => ({
                                    value: status.id.toString(),
                                    label: status.description
                                }))]}
                                {...register('INTERNAL_CATEGORY')}
                                className="order-2"
                                classNamePrefix={'fim'}
                                isSearchable={false}
                                onChange={({ value }) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INTERNAL_CATEGORY': value}
                                    })
                                }}
                            />*/}
                        {/*<select
                            id="INTERNAL_CATEGORY"
                            className="order-2"
                            {...register('INTERNAL_CATEGORY')}
                            value={submission[order.ORDER_REF]?.INTERNAL_CATEGORY || order?.INTERNAL_CATEGORY}
                            onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                })
                            }}
                            >
                                <option value="">- Select -</option>
                            {categories.map(status => (
                                <option value={status.id.toString()} key={status.id.toString()}>{status.description}</option>
                            ))}
                            </select>*/}
                        </div>
                        )}
                        <label htmlFor="INTERNAL_CATEGORY">Inspection category?</label>
                    </div>

                    <div className="form-field form-field--select col-span-12" style={{zIndex:1}}>
                        {subcategories && (
                        <div className="select">
                            {/*<Select
                                defaultValue={submission[order.ORDER_REF]?.INTERNAL_SUBCATEGORY || order?.INTERNAL_SUBCATEGORY}
                                //placeholder={submission[order.ORDER_REF]?.INTERNAL_SUBCATEGORY || order?.INTERNAL_SUBCATEGORY || "- Select -"}
                                placeholder={subcategories.find(status => {
                                    let selected = submission[order.ORDER_REF]?.INTERNAL_SUBCATEGORY || order?.INTERNAL_SUBCATEGORY
                                    return status.id.toString() === selected
                                })?.description || '- Select -'}
                                options={subcategories.map(status => ({
                                    value: status.id.toString(),
                                    label: status.description
                                }))}
                                {...register('INTERNAL_SUBCATEGORY')}
                                className="order-2"
                                classNamePrefix={'fim'}
                                isSearchable={false}
                                onChange={({ value }) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INTERNAL_SUBCATEGORY': value}
                                    })
                                }}
                            />*/}

                            <Menu
                                hot={submission[order.ORDER_REF]?.INTERNAL_SUBCATEGORY}
                                fallback={order?.INTERNAL_SUBCATEGORY}
                                options={subcategories.map(status => ({
                                    value: status.id.toString(),
                                    label: status.description
                                }))}
                                onClear={() => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INTERNAL_SUBCATEGORY': ''}
                                    })
                                }}
                                onSelect={(value:string) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'INTERNAL_SUBCATEGORY': value}
                                    })
                                }}
                            />
                        {/*<select
                            id="INTERNAL_SUBCATEGORY"
                            className="order-2"
                            {...register('INTERNAL_SUBCATEGORY')}
                            value={submission[order.ORDER_REF]?.INTERNAL_SUBCATEGORY || order?.INTERNAL_SUBCATEGORY}
                            onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                })
                            }}
                            >
                                <option value="">- Select -</option>
                            {subcategories.map(status => (
                                <option value={status.id.toString()} key={status.id.toString()}>{status.description}</option>
                            ))}
                            </select>*/}
                        </div>
                        )}
                        <label htmlFor="INTERNAL_SUBCATEGORY">Inspection sub category?</label>
                    </div>

                    <div className="form-field form-field--radios col-span-12">
                        <h3>Sample returned?</h3>
                        <ul>
                            <li>
                                <input
                                    type="radio"
                                    id="SAMPLE_RETURNED__y"
                                    name="SAMPLE_RETURNED"
                                    value="1"
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 1}
                                        })
                                    }}
                                    checked={submission[order.ORDER_REF]?.SAMPLE_RETURNED === 1 || order.SAMPLE_RETURNED === 1}
                                />
                                <label htmlFor="SAMPLE_RETURNED__y">Yes</label>
                            </li>
                            <li>
                                <input
                                    type="radio"
                                    id="SAMPLE_RETURNED__n"
                                    name='SAMPLE_RETURNED'
                                    value="0"
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 0}
                                        })
                                    }}
                                    checked={submission[order.ORDER_REF]?.SAMPLE_RETURNED === 0 || order.SAMPLE_RETURNED === 0}
                                />
                                <label htmlFor="SAMPLE_RETURNED__n">No</label>
                            </li>
                        </ul>
                    </div>
                    <div className="form-field form-field--radios col-span-12">
                        <h3>Photos returned?</h3>
                        <ul>
                            <li>
                                <input
                                    type="radio"
                                    id="PHOTOS_RETURNED__y"
                                    name="PHOTOS_RETURNED"
                                    value="1"
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 1}
                                        })
                                    }}
                                    checked={submission[order.ORDER_REF]?.PHOTOS_RETURNED === 1 || order.PHOTOS_RETURNED === 1}
                                />
                                <label htmlFor="PHOTOS_RETURNED__y">Yes</label>
                            </li>
                            <li>
                                <input
                                    type="radio"
                                    id="PHOTOS_RETURNED__n"
                                    name='PHOTOS_RETURNED'
                                    value="0"
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 0}
                                        })
                                    }}
                                    checked={submission[order.ORDER_REF]?.PHOTOS_RETURNED === 0 || order.PHOTOS_RETURNED === 0}
                                />
                                <label htmlFor="PHOTOS_RETURNED__n">No</label>
                            </li>
                        </ul>
                    </div>
                    <div className="form-field form-field--radios col-span-12">
                        <h3>Paint on site?</h3>
                        <ul>
                            <li>
                                <input
                                    type="radio"
                                    id="PAINT_ON_SITE__y"
                                    name="PAINT_ON_SITE"
                                    value="1"
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 1}
                                        })
                                    }}
                                    checked={submission[order.ORDER_REF]?.PAINT_ON_SITE === 1 || order.PAINT_ON_SITE === 1}
                                />
                                <label htmlFor="PAINT_ON_SITE__y">Yes</label>
                            </li>
                            <li>
                                <input
                                    type="radio"
                                    id="PAINT_ON_SITE__n"
                                    name='PAINT_ON_SITE'
                                    value="0"
                                    onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                        if(!valid) { return; }
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: 0}
                                        })
                                    }}
                                    checked={submission[order.ORDER_REF]?.PAINT_ON_SITE === 0 || order.PAINT_ON_SITE === 0}
                                />
                                <label htmlFor="PAINT_ON_SITE__n">No</label>
                            </li>
                        </ul>
                    </div>

                    {order.BUS_ID.toString() === '2' ? (
                    <div className="form-field form-field--select col-span-12">
                        <div className="select">
                            <Menu
                                hot={submission[order.ORDER_REF]?.REMEDIAL_PROCESSING_PRIORITY}
                                fallback={order?.REMEDIAL_PROCESSING_PRIORITY}
                                options={remedialProcessingPriority.map(status => ({
                                    value: status.id.toString(),
                                    label: status.label
                                }))}
                                onClear={() => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'REMEDIAL_PROCESSING_PRIORITY': ''}
                                    })
                                }}
                                onSelect={(value:string) => {
                                    setSubmission({
                                        ...submission,
                                        [order.ORDER_REF]: {...submission[order.ORDER_REF], 'REMEDIAL_PROCESSING_PRIORITY': value}
                                    })
                                }}
                            />
                        </div>
                        <label htmlFor="REMEDIAL_PROCESSING_PRIORITY">Remedial Processing Priority</label>
                    </div>
                    ) : null}
                </fieldset>


                <fieldset className="md:col-start-2 md:col-span-1 md:row-start-2 relative" style={{zIndex:10000}}>
                    <div className="form-field form-field--combo col-span-12" style={{zIndex: 3}}>
                        <h3>Sprayer</h3>
                        <ul>
                            <li>
                                {/*<Select
                                    defaultValue={submission[order.ORDER_REF]?.SPRAYER_DAYS || order?.SPRAYER_DAYS}
                                    options={Array.from({ length: 6 }, (o, i:number) => ({
                                        value: i,
                                        label: i
                                    }))}
                                    {...register('SPRAYER_DAYS')}
                                    id={'SPRAYER_DAYS'}
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    placeholder={submission[order.ORDER_REF]?.SPRAYER_DAYS || order?.SPRAYER_DAYS || 0}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'SPRAYER_DAYS': value}
                                        })
                                    }}
                                />*/}
                                <Menu
                                    hot={submission[order.ORDER_REF]?.SPRAYER_DAYS}
                                    fallback={order?.SPRAYER_DAYS}
                                    defaultLabel={'0'}
                                    options={Array.from({ length: 6 }, (o, i:number) => ({
                                        value: i.toString(),
                                        label: i.toString()
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'SPRAYER_DAYS': '0'}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'SPRAYER_DAYS': value}
                                        })
                                    }}
                                />
                                <label htmlFor="SPRAYER_DAYS">Days</label>
                            </li>
                            <li>
                                {/*<Select
                                    defaultValue={submission[order.ORDER_REF]?.SPRAYER_HOURS || order?.SPRAYER_HOURS}
                                    placeholder={submission[order.ORDER_REF]?.SPRAYER_HOURS || order?.SPRAYER_HOURS || 0}
                                    options={Array.from({ length: 24 }, (o, i:number) => ({
                                        value: i,
                                        label: i
                                    }))}
                                    {...register('SPRAYER_HOURS')}
                                    id={'SPRAYER_HOURS'}
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'SPRAYER_HOURS': value}
                                        })
                                    }}
                                />*/}

                                <Menu
                                    hot={submission[order.ORDER_REF]?.SPRAYER_HOURS}
                                    fallback={order?.SPRAYER_HOURS}
                                    defaultLabel={'0'}
                                    options={Array.from({ length: 24 }, (o, i:number) => ({
                                        value: i.toString(),
                                        label: i.toString()
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'SPRAYER_HOURS': '0'}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'SPRAYER_HOURS': value}
                                        })
                                    }}
                                />
                                <label htmlFor="SPRAYER_HOURS">Hours</label>
                            </li>
                        </ul>
                    </div>
                    <div className="form-field form-field--combo col-span-12" style={{zIndex: 2}}>
                        <h3>1 man</h3>
                        <ul>
                            <li>
                                {/*<Select
                                    defaultValue={submission[order.ORDER_REF]?.ONE_MAN_DAYS || order?.ONE_MAN_DAYS}
                                    placeholder={submission[order.ORDER_REF]?.ONE_MAN_DAYS || order?.ONE_MAN_DAYS || 0}
                                    options={Array.from({ length: 6 }, (o, i:number) => ({
                                        value: i,
                                        label: i
                                    }))}
                                    {...register('ONE_MAN_DAYS')}
                                    id={'ONE_MAN_DAYS'}
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'ONE_MAN_DAYS': value}
                                        })
                                    }}
                                />*/}

                                <Menu
                                    hot={submission[order.ORDER_REF]?.ONE_MAN_DAYS}
                                    fallback={order?.ONE_MAN_DAYS}
                                    defaultLabel={'0'}
                                    options={Array.from({ length: 6 }, (o, i:number) => ({
                                        value: i.toString(),
                                        label: i.toString()
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'ONE_MAN_DAYS': '0'}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'ONE_MAN_DAYS': value}
                                        })
                                    }}
                                />
                                <label htmlFor="ONE_MAN_DAYS">Days</label>
                            </li>
                            <li>
                                {/*<Select
                                    defaultValue={submission[order.ORDER_REF]?.ONE_MAN_HOURS || order?.ONE_MAN_HOURS}
                                    placeholder={submission[order.ORDER_REF]?.ONE_MAN_HOURS || order?.ONE_MAN_HOURS || 0}
                                    options={Array.from({ length: 24 }, (o, i:number) => ({
                                        value: i,
                                        label: i
                                    }))}
                                    {...register('ONE_MAN_HOURS')}
                                    id={'ONE_MAN_HOURS'}
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'ONE_MAN_HOURS': value}
                                        })
                                    }}
                                />*/}
                                <Menu
                                    hot={submission[order.ORDER_REF]?.ONE_MAN_HOURS}
                                    fallback={order?.ONE_MAN_HOURS}
                                    defaultLabel={'0'}
                                    options={Array.from({ length: 24 }, (o, i:number) => ({
                                        value: i.toString(),
                                        label: i.toString()
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'ONE_MAN_HOURS': '0'}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'ONE_MAN_HOURS': value}
                                        })
                                    }}
                                />

                                <label htmlFor="ONE_MAN_HOURS">Hours</label>
                            </li>
                        </ul>
                    </div>
                    <div className="form-field form-field--combo col-span-12" style={{zIndex: 1}}>
                        <h3>2 man</h3>
                        <ul>
                            <li>
                                {/*<Select
                                    defaultValue={submission[order.ORDER_REF]?.TWO_MEN_DAYS || order?.TWO_MEN_DAYS}
                                    placeholder={submission[order.ORDER_REF]?.TWO_MEN_DAYS || order?.TWO_MEN_DAYS || 0}
                                    options={Array.from({ length: 6 }, (o, i:number) => ({
                                        value: i,
                                        label: i
                                    }))}
                                    {...register('TWO_MEN_DAYS')}
                                    id={'TWO_MEN_DAYS'}
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'TWO_MEN_DAYS': value}
                                        })
                                    }}
                                />*/}
                                <Menu
                                    hot={submission[order.ORDER_REF]?.TWO_MEN_DAYS}
                                    fallback={order?.TWO_MEN_DAYS}
                                    defaultLabel={'0'}
                                    options={Array.from({ length: 6 }, (o, i:number) => ({
                                        value: i.toString(),
                                        label: i.toString()
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'TWO_MEN_DAYS': '0'}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'TWO_MEN_DAYS': value}
                                        })
                                    }}
                                />
                                <label htmlFor="TWO_MEN_DAYS">Days</label>
                            </li>
                            <li>
                                {/*<Select
                                    defaultValue={submission[order.ORDER_REF]?.TWO_MEN_HOURS || order?.TWO_MEN_HOURS}
                                    placeholder={submission[order.ORDER_REF]?.TWO_MEN_HOURS || order?.TWO_MEN_HOURS || 0}
                                    options={Array.from({ length: 24 }, (o, i:number) => ({
                                        value: i,
                                        label: i
                                    }))}
                                    {...register('TWO_MEN_HOURS')}
                                    id={'TWO_MEN_HOURS'}
                                    classNamePrefix={'fim'}
                                    isSearchable={false}
                                    onChange={({ value }) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'TWO_MEN_HOURS': value}
                                        })
                                    }}
                                />*/}
                                <Menu
                                    hot={submission[order.ORDER_REF]?.TWO_MEN_HOURS}
                                    fallback={order?.TWO_MEN_HOURS}
                                    defaultLabel={'0'}
                                    options={Array.from({ length: 24 }, (o, i:number) => ({
                                        value: i.toString(),
                                        label: i.toString()
                                    }))}
                                    onClear={() => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'TWO_MEN_HOURS': '0'}
                                        })
                                    }}
                                    onSelect={(value:string) => {
                                        setSubmission({
                                            ...submission,
                                            [order.ORDER_REF]: {...submission[order.ORDER_REF], 'TWO_MEN_HOURS': value}
                                        })
                                    }}
                                />
                                <label htmlFor="TWO_MEN_HOURS">Hours</label>
                            </li>
                        </ul>
                    </div>


                    <div className="form-field form-field--date col-span-12 items-start">
                        <div className="input">
                            <input
                                type="date"
                                className="h-10 w-32 text-left border appearance-none bg-white py-3 border-neutral-300 rounded-sm outline-none text-black"
                                id="FITTER_OFFSITE_DATE"
                                name="FITTER_OFFSITE_DATE"
                                defaultValue={order.FITTER_OFFSITE_DATE ?? ''}
                                onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                    if(!valid) { return; }
                                    debounced(name, value)
                                }}
                            />
                            <CalendarIcon />
                        </div>
                        <label htmlFor="FITTER_OFFSITE_DATE">Fitter Off Site Date<small className="block text-balance">Date when fitter will leave a live from first delivery</small></label>
                    </div>
                </fieldset>



                <fieldset className="md:col-span-2 border-t pt-4 mt-8">
                    <legend className="block whitespace-nowrap">Remedial Report</legend>

                    {reasons && order.ORDER_REF && (
                        <MattersArising
                            reasons={reasons}
                            register={register}
                            order={order}
                            causes={causes}
                            actions={{
                                submission,
                                setSubmission
                            }}
                        />
                    )}

                </fieldset>

                <fieldset className="md:col-span-2 border-t pt-4 mt-8">
                    <legend className="block whitespace-nowrap">Materials</legend>
                    <div className="form-field form-field--textarea col-span-12">
                        <textarea
                            name="MATERIALS_NOTE"
                            className="w-100"
                            rows={6}
                            placeholder={'(1500 characters maximum)'}
                            maxLength={1500}
                            value={(submission[order.ORDER_REF] && submission[order.ORDER_REF].hasOwnProperty('MATERIALS_NOTE')) ? submission[order.ORDER_REF].MATERIALS_NOTE : order.MATERIALS_NOTE}

                            onChange={({ target: { name, value, validity: { valid } }, ...props }) => {
                                if(!valid) { return; }

                                //debounced(name, value);
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], [name]: value}
                                })
                            }}
                            id="MATERIALS_NOTE" />
                            <button type="button" onClick={() => {
                                setSubmission({
                                    ...submission,
                                    [order.ORDER_REF]: {...submission[order.ORDER_REF], MATERIALS_NOTE: ''}
                                })
                            }}>Clear Materials</button>
                        <label htmlFor="MATERIALS_NOTE" className="sr-only">Materials note</label>
                    </div>
                </fieldset>

                <fieldset className="md:col-start-1 md:col-span-2 border-t pt-4 mt-10">
                    <legend className="block">Images</legend>
                    <Photos
                        register={register}
                        unregister={unregister}
                    />
                </fieldset>

                <fieldset className="md:col-span-2 md:col-start-1  max-w-4xl w-full mx-auto">
                    <div className="form-field mt-10 col-span-12">
                    {online === true && (
                        <Button disabled={status === 'sending-report'} type="submit">
                            {status === 'sent' && <span>Report sent</span>}
                            {status === 'sending-report' && <span>Report sending&hellip;</span>}
                            {status === '' && <span>Send Report</span>}
                        </Button>
                    )}
                    {online === false && <p className="text-center text-neutral-500 text-lg font-semibold antialiased mx-auto bg-neutral-300 py-3 px-6 rounded">Connect to wifi send APR</p>}
                    </div>
                </fieldset>
            </form>

        </div>
    )
}
export default Report

